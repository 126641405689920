<template>
  <div class="login test">
    <div class="row m-5" v-if="!success">
        <div class="col-md-12 m-5">
            <div class="row">
                <div class="col-md-3 col-md-offset-3 form-label text-right">
                    E-Mail Adresse
                </div>
                <div class="col-md-6">
                    <input v-model="email" type="text" />
                </div>
            </div>
            <div class="row" v-if="errorMessage">
                <div class="col-md-9 col-md-offset-3 form-label errorMessage">
                    {{ errorMessage }}
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 form-label" style="text-align: -webkit-center;">
                    <vue-recaptcha style="width: 100%;" :loadRecaptchaScript="true" ref="recaptcha" @verify="onVerify" @expired="onExpired" sitekey="6Le1XkYUAAAAAONwMr7tgshbd15lV14YQPvUtPNY"></vue-recaptcha>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center">
                    <input v-if="loading" value="Zurücksetzen" class="disabled" type="button" disabled />
                    <input v-else @click="login" value="Zurücksetzen" type="button" />
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-else>
        <div class="col-md-6 m-5">Bitte prüfen Sie Ihre E-Mails. Beachten Sie, dass das Zurücksetzen des Passworts nur einmal in 5 Minuten beantragt werden kann. Sollten Sie keine E-Mail erhalten haben, versuchen Sie es in 5 Minuten nochmal.</div>
    </div>
  </div>
</template>

<script>

import config from '@/config.js';
import VueRecaptcha from 'vue-recaptcha';

/* eslint-disable */
export default {
    name: 'PasswordReset',
    data() {
        return {
            email: '',
            loading: false,
            errorMessage: null,
            success: false,
            captcha: false,
        };
    },
    components: {
        VueRecaptcha		
    },
  methods: {
    onVerify(res) {
        this.captcha = res;
    },
    onExpired() {
        this.captcha = false;
    },
    login() {
        this.loading = true;
        let data = {
            email: this.email
        };

        data.captcha = this.captcha;

        $.post(`${config.BaseURL}password/email`, data).then((res) => {
            this.loading = false;
            this.errorMessage = null;
            this.success = true;
        }, (error) => {
            if (error.responseJSON && error.responseJSON.errors) {
                let messages = Object.keys(error.responseJSON.errors).map(key => error.responseJSON.errors[key][0]);

                this.errorMessage = messages[0];
            } else {
                this.errorMessage = 'Es ist ein Problem aufgetreten. Versuchen Sie es später noch einmal.';
            }

            this.loading = false;
        });
    }
  },

}
</script>

<style scoped>
    .form-label {
        padding: 5px 0;
    }
    input[type=text], input[type=password] {
        width: 100%;
        margin-bottom: 10px;
        border: 0px;
        background-color: rgba(255,255,255,0.7);
        padding: 5px 10px;
    }
    input[type=button] {
        background-color: #003781;
        border: 0;
        color: #ffffff;
        padding: 5px 15px;
    }

    .passwort-vergessen {
        margin-left: 10px;
        line-height: 20px;
        font-size: 14px;
    }

    .passwort-vergessen a {
        font-size: 17px;
    }

    .passwort-vergessen div {
        font-size: 16px;
    }

    .lock-img {
        height: 40px;
        width: auto;
    }
    .lock-img, .passwort-vergessen {
        float: left;
    }

    .errorMessage {
        color: rgb(180, 0, 0);
        font-weight: bold;
        text-align: right;
        padding-right: 15px;
    }

    .disabled {
        opacity: 0.7;
    }
</style>
