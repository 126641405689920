<template>
    <div>Leider konnte Ihre E-Mailadresse nicht bestätigt werden.<br /><br />Ihr Bestätigungscode ist scheinbar abgelaufen oder nicht gültig.</div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'ConfirmError',
}
</script>
