<template>
  <div class="login">
    <div class="row ml-5">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-3 col-md-offset-2 py-2 px-1 form-label">
                    E-Mail
                </div>
                <div class="col-md-7 py-2 px-1">
                    <input v-model="username" autocomplete="off" type="text" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-md-offset-2 py-2 px-1 form-label">
                    Passwort
                </div>
                <div class="col-md-7 py-2 px-1">
                    <input v-model="password" type="password" autocomplete="off" @keyup.enter="login" />
                </div>
            </div>
            <div class="row" v-if="errorMessage">
                <div class="col-md-9 col-md-offset-2 form-label errorMessage">
                    {{ errorMessage }}
                </div>
            </div>
            <div class="row" v-if="showCaptcha">
                <div class="col-md-9 col-md-offset-2 py-2 px-1 form-label">
                    <vue-recaptcha style="width: 100%;" ref="recaptcha" @verify="onVerify" @expired="onExpired" sitekey="6Le1XkYUAAAAAONwMr7tgshbd15lV14YQPvUtPNY"></vue-recaptcha>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-md-offset-2 py-2 px-1 form-label">
                </div>
                <div class="col-md-7 py-2 px-1">
                    <input v-if="loading" value="Einloggen" class="disabled" type="button" disabled />
                    <input v-else @click="login" value="Einloggen" type="button" />
                </div>
            </div>

            <div class="row registrationForm">
                <div class="col-md-12 col-md-offset-5">
                    <div class="newHereBox">
                        <div class="lineBox"></div>
                        <div class="newHere">Neu beim Allianz Maklertools Portal?</div>
                        <div class="lineBox"></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-7 col-md-offset-5">
                    <router-link to="register"><input value="Erstellen Sie hier Ihren persönlichen Account" type="button" class="registerBtn" /></router-link>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <img class="lock-img" src="static/images/lock.png">
            <div class="passwort-vergessen">
                <div>
                    Sie haben Ihr Passwort vergessen? <br />
                    <router-link to="/request">> Passwort vergessen / Zugang gesperrt.</router-link>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import config from '@/config.js';
import VueRecaptcha from 'vue-recaptcha';

/* eslint-disable */
export default {
  name: 'Login',
  data() {
      return {
          username: '',
          password: '',
          errorMessage: null,
          loading: false,
          showCaptcha: false,
          captcha: false,
          redirect: null
      };
  },
  components: { VueRecaptcha },
  created() {
    if (this.$route.params.http && this.$route.params.redirect) {
        this.$store.commit('redirect', `${this.$route.params.http}://${this.$route.params.redirect}`);
    }
  },
  methods: {
    onVerify(res) {
        this.captcha = res;
    },
    onExpired() {
        this.captcha = false;
    },
    login() {
        this.loading = true;
        let loginData = {
            grant_type: 'password',
            client_id: config.oauth.id,
            client_secret: config.oauth.secret,
            scope: '*',
            username: this.username,
            password: this.password
        };

        if (this.showCaptcha) {
          loginData.captcha = this.captcha;
        }

        $.post(`${config.BaseURL}oauth/token`, loginData).then((res) => {
            this.loading = false;
            this.errorMessage = null;
            this.$store.commit('setToken', res.access_token);

            this.$store.commit('getUserData');
        }, (error) => {
            if (error.responseJSON && error.responseJSON.errors && !!error.responseJSON.errors.captcha) {
              this.errorMessage = 'Bitte Captcha lösen';
              this.loading = false;
              this.showCaptcha = true;
            } else {
              this.loading = false;
              this.password = '';
              this.errorMessage = 'Falsche E-Mail oder falsches Passwort.';
            }
        });
    }
  }
}
</script>

<style lang="scss" scoped>
    .newHereBox {
        width: 100%;
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .lineBox {
            height: 1px;
            background-color: #424242;
            flex-grow: 1;
        }

        .newHere {
            flex-grow: 0;
            margin: 0px 10px;
            color: #424242;
            font-size: 15px;
        }
    }

    .form-label {
        padding: 5px 0;
        font-weight: bold;
    }
    input[type=text], input[type=password] {
        width: 100%;
        margin-bottom: 10px;
        border: 0px;
        background-color: rgba(255,255,255,0.7);
        padding: 5px 10px;
    }
    input[type=button] {
        background-color: #003781;
        border: 0;
        color: #ffffff;
        padding: 5px 15px;
    }

    .passwort-vergessen {
        margin-left: 10px;
        line-height: 20px;
        font-size: 14px;
    }

    .passwort-vergessen a {
        font-size: 17px;
    }

    .passwort-vergessen div {
        font-size: 16px;
    }

    .lock-img {
        height: 40px;
        width: auto;
    }
    .lock-img, .passwort-vergessen {
        float: left;
    }

    .errorMessage {
        color: rgb(180, 0, 0);
        font-weight: bold;
        text-align: right;
        padding-right: 15px;
    }

    .disabled {
        opacity: 0.7;
    }

    .registerBtn {
        background-color: #9d9c9c !important;
        width: 100%;
        margin-top: 15px;
    }

    .registerBtn:hover {
        background-color: #a7a7a7 !important;
    }


    @media (min-width: 992px) {
        .col-md-offset-2 {
            margin-left: 16.66666667%;
        }
        .col-md-offset-5 {
            margin-left: 41.66666667%;
        }
    }
    

</style>
