<script>
  export default {
    name:'DateRangeHelper',
    computed: {
      formatedDateRangeMessage(){
        let dateRange = this.$store.getters.dateRange;
        return 'im Zeitraum vom ' + this.formatDate(dateRange.start) + ' bis  ' + this.formatDate(dateRange.end) + ' ';
      }
    },
    methods: {
      formatDate(date) {
        if (date === undefined || date === null || Object.keys(date).length === 0) return;

        let parts = date.split('-');
        if (parts.length === 3) {
          return `${parts[2]}.${parts[1]}.${parts[0]}`;
        }

        return '';
      }
    }
  }
</script>
