<template>
    <li @click="chooseCategory(item.id, $event)" @mouseover="mouseOverCat" @mouseout="mouseOutCat">
        <span class="first-level" v-html="item.name"></span>
        <span class="subcatoverlay" v-if="item.all_children.length"></span>
        <span v-if="item.all_children.length > 0" class="arrow-right"></span>
        <ul class="subcat" v-if="item.all_children.length > 0 && show">
            <li @click="chooseCategory(subcat.id, $event)" v-for="subcat in item.all_children" :key="subcat.id"><span class="second-level">{{ subcat.name }}</span></li>
        </ul>
    </li>
</template>

<script>
/* eslint-disable */
export default {
  name: 'Sparte',
  props: ['item'],
  data() {
    return {
        show: false
    };
  },
  methods:{
        chooseCategory(categoryId, event) {
            event.stopPropagation();

            this.$emit('input', categoryId);

            this.$store.commit('track', {
				type: 'category',
                action: 'filter',
                ref: categoryId
			});
        },
        mouseOverCat() {
            this.show = true;
        },
        mouseOutCat() {
            this.show = false;
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.arrow-right {
    width: 0; 
    height: 0; 
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    position: absolute;
    top: 25px;
    right: 7px;
    border-left: 5px solid #003781;
}
ul {
    list-style:none;
    position: absolute;
    background-color: #ececec; 
    padding: 5px 15px 15px 15px;
    left: 0;
    width:250px;
}
.first-level {
    width:100%;
    height:100%;
}
li:hover>.first-level {
    color:#003781;
}
li:hover>.second-level {
    color:#003781;
}
li {
    height: 35px;
    padding-top: 15px;
    position: relative;
}
.hassubcat {
    width: 20px;
    height: 80px;
    position: absolute;
    background-color: red;
}
.subcat {
    position: absolute;
    top: -5px;
    left: 109%;
}
.subcatoverlay {
    z-index:99999;
    position: absolute;
    height: 45px;
    width: 122%;
    top: -5px;
    left: -15px;
}

</style>
