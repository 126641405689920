<template>
  <div class="inner" style=" text-align:default;;">
    <div>
        <div>
          <h2>Allianz Deutschland AG<strong><br></strong></h2>
          <p><strong>Vorsitzender des Aufsichtsrats</strong><br>Dr. Axel Theis</p>
          <p><strong>Vorstand</strong><br>
		Dr. Klaus-Peter Röhler, Vorsitzender<br>
		Fabio De Ferrari<br>
		Katja de la Viña<br>
		Dr. Markus Faulhaber<br>
		Bernd Heinemann<br>
		Nina Klingspor<br>
		Joachim Müller<br>
		Aylin Somersan Coqui<br>
		</p>
          <p>Königinstraße 28 <br>80802 München</p>
          <p>Telefonnummer: +49 89 3800-0 <br>E-Mail Sammelpostfach: <a href="mailto:info@allianz.de" target="_self" title="">info@allianz.de</a></p>
          <p><br><strong>Sitz der Gesellschaft:</strong> München <br><strong>Registergericht:</strong> Amtsgericht München HRB 158878 <br><br><strong>Aufsichtsbehörde:</strong> Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin), Bonn <br><br><strong>Für Umsatzsteuerzwecke:</strong> USt-IdNr.: DE 814 580 981</p>
          <p>Finanz- und Versicherungsleistungen i.S.d. UStG / MwStSystRL sind von der Umsatzsteuer befreit.</p>
        </div>
        <div>
          <div><br>Sitz der Gesellschaft: München</div>
          <div>
              <br>Registergericht: Amtsgericht München HRB 158878<br>
              <p><br>Stand 05/2019</p>
              <p><a href="https://www.allianz.de/datenschutz/" target="_blank" rel="noopener noreferrer">Datenschutz</a> | <a href="https://www.allianz.de/nutzungsbedingungen/" target="_blank" rel="noopener noreferrer">Nutzungsbedingungen</a></p>
              <p></p>
          </div>
        </div>
        <div>
          <div>
              <h3>Adressen und Telefonnummern der Allianz Unternehmen</h3>
              <p><a class="link text" target="_blank" rel="noopener noreferrer" href="https://www.allianz.de/service/kontakt/" title="Erfahren Sie mehr über die Adressen und Telefonnummern der Allianz">Deutschland und International</a></p>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "Imprint"
};
</script>

<style scoped>

</style>
