<template>
	<v-app>
		<v-main>
			<v-dialog persistent v-if="showContact" v-model="contact" max-width="500px">
				<v-card class="allianzDialog">
					<v-card-title>
						<span class="headline">Feedback</span>
					</v-card-title>
					<v-card-text class="mt-8">
						<v-container grid-list-md>
							<v-row justify="center">
								<v-col md="12">
									<v-select :items="items" v-model="contactForm.type" label="Typ" :rules="rules" required :disabled="saving"></v-select>
								</v-col>
								<v-col md="12">
									<v-text-field label="Vorname" class="allianzInput" v-model="contactForm.firstname" :rules="rules" required :disabled="saving"></v-text-field>
								</v-col>
								<v-col md="12">
									<v-text-field label="Nachname" class="allianzInput" v-model="contactForm.lastname" :rules="rules" required :disabled="saving"></v-text-field>
								</v-col>
								<v-col md="12">
									<v-text-field label="E-Mail" class="allianzInput" v-model="contactForm.email" :rules="rules" required :disabled="saving"></v-text-field>
								</v-col>
								<v-col md="12">
									<v-text-field label="Betreff" class="allianzInput" v-model="contactForm.subject" :rules="rules" required :disabled="saving"></v-text-field>
								</v-col>
								<v-col md="12">
									<v-text-field class="allianzInput" label="Nachricht/Beschreibung:" auto-grow v-model="contactForm.text" multi-line :rules="rules" required :disabled="saving"></v-text-field>
								</v-col>
								<v-col md="12">
									<vue-recaptcha style="width: 100%;" :loadRecaptchaScript="true" ref="recaptcha" @verify="onVerify" @expired="onExpired" sitekey="6Le1XkYUAAAAAONwMr7tgshbd15lV14YQPvUtPNY"></vue-recaptcha>
								</v-col>
								<v-col md="12" class="errorMessage" v-if="errorMessage">
									{{ errorMessage }}
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<button class="btns" @click="close">Abbrechen</button>
						<v-spacer></v-spacer>
						<button class="btns allianzBtn disabledButton" disabled v-if="saving">Absenden</button>
						<button class="btns allianzBtn" @click="submit" v-else>Absenden</button>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<div>
				<div v-if="!loaded" class="loader">
					<GridLoader color="#003781" />
				</div>
				<div v-else :class="'currentContent ' + currentPage">
					<div class="row header">
						<div class="col-xs-6 col-md-6">
							<router-link to="/">
								<img src="static/images/logo.png" class="logo" />
							</router-link>
						</div>
						<div v-if="isLoggedIn && userData.admin === 1" class="col-xs-6 col-md-6">
							<div class="row">
                <div class="col-md-12">
                  <router-link class="text-xs-right" to="/admin/index"><img class="settings" src="static/images/settings.png" /></router-link>
								</div>
							</div>
						</div>
					</div>

					<div class="tableRow">
						<div class="breadcrump-content">
							<router-link v-if="showHomeButton" to="/">
								<img class="home" src="static/images/home.png" />
							</router-link>
						</div>
						<div class="header-seperator siteColor wisdom">&nbsp;</div>
						<div class="search-content">
							<div class="text-right" v-if="isLoggedIn">
								<!-- <div class="row">
									<div class="col-md-12" >
									{{ currentUser }}
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<div class="dropdown">
											<span>Mein Konto</span>
											<div class="dropdown-content">
												<span class="logout-link" @click="deleteuser">Account löschen</span>
												<router-link to="/changepassword">Passwort ändern</router-link>
											</div>
											|  <span class="logout-link" @click="logout">Abmelden</span>
										</div>
									</div>
								</div> -->
								<h3 style="padding-right: 15px">{{ currentUser }}</h3>
								<nav id="primary_nav_wrap">
									<ul>
									<li><span>Mein Konto</span>
										<ul>
										<li><span class="logout-link" @click="deleteuser">Account löschen</span></li>
										<li><router-link to="/changepassword"><span>Passwort ändern</span></router-link></li>
										</ul>
									</li>
									<li> <span class="logout-link" @click="logout">Abmelden</span></li>
									</ul>
								</nav>
							</div>
						</div>
					</div>

					<div class="row no-margin relative">
						<div class="col-md-12 background">
							<div class="row">
								<div v-if="!accessError || isWhitelisted($route)" id="cmi" class="col-md-12 background startBG innerContent" style="background-color: #bcbcb1;">
									<router-view/>
								</div>
								<div v-else class="col-md-12 no-access background startBG innerContent" v-html="accessError" style="background-color: #bcbcb1;"></div>
							</div>
						</div>

						<div class="row no-margin">
							<div class="col-md-12 background overlayContainer pt-0">
								<div class="tableRow secondRow">
									<div class="breadcrump-content"></div>
									<div class="header-seperator siteColor wisdomBottomRight" v-html="header"></div>
									<div class="search-content">&nbsp;</div>
								</div>
							</div>
						</div>
						<!-- New Start -->
						<div v-if="['statistic'].indexOf($route.name) > -1" class="row no-margin">
							<div class="col-md-12 background MaklerToolsAdmin">
								<div><a target="_blank" rel="noopener noreferrer" href="https://www.allianz-maklertools.de/admin.php">zum Maklertools-Reporting</a></div>
							</div>
						</div>
						<!-- New Stop  -->
					</div>

					<div class="row">
						<div class="col-md-12 text-right footerBar">
							<a href="#" @click="showContact = true">Feedback</a>
							<a href="https://www.allianz.de/datenschutz/" target="_blank" rel="noopener noreferrer">Datenschutz</a>
							<a href="https://www.allianz.de/impressum/" target="_blank" rel="noopener noreferrer">Impressum</a>
							<!-- <router-link to="/imprint">Impressum</router-link> -->
						</div>
					</div>
				</div>
			</div>
		</v-main>
	</v-app>
</template>

<script>

import '@/assets/css/bootstrap.min.css';
import '@/assets/css/style.css';
import { mapGetters } from 'vuex';
import GridLoader from 'vue-spinner/src/GridLoader.vue';
import config from '@/config.js';
import swal from 'sweetalert2';
import VueRecaptcha from 'vue-recaptcha';

export default {
	data(){
		return {
			header: 'Allianz <b>Maklertools</b> PORTAL',
			contact: false,
			captcha: false,
			showContact: false,
			errorMessage: null,
			contactForm: {
				type: 'Feedback',
				firstname: '',
				lastname: '',
				email: '',
				subject: '',
				text: '',
			},
			saving: false,
			rules: [
				v => !!v || 'Dieses Feld wird benötigt'
			],
			items: ['Feedback', 'Sonstiges', 'Technisches Problem']
		};
	},
	methods: {
		onVerify(res) {
        this.captcha = res;
		},
		onExpired() {
			this.captcha = false;
		},
		close() {
			this.saving = false;
			this.contact = false;
		},
		submit() {

			this.saving = true;

			this.contactForm.captcha = this.captcha;

			$.post(`${config.BaseURL}api/contact`, this.contactForm).then((res) => {
					this.errorMessage = null;

					swal({
						type: 'success',
						title: 'Ihre Nachricht wurde abgeschickt!',
						showConfirmButton: false,
						timer: 1500
					});

					this.close();
			}, (error) => {
					if (error.responseJSON && error.responseJSON.errors) {
							let messages = Object.keys(error.responseJSON.errors).map(key => error.responseJSON.errors[key][0]);

							this.errorMessage = messages[0];
					} else {
							this.errorMessage = 'Es ist ein Problem aufgetreten. Versuchen Sie es später noch einmal.';
					}

					this.saving = false;
			});
		},
		logout: function() {
			$.get(`${config.BaseURL}api/logout`);
			this.$store.commit('setToken', '');
			this.$router.push('/login');
		},
		deleteuser: function() {
				swal({
					title: 'Möchten Sie Ihren Account endgültig löschen?',
					text: "Ihre personenbezogenen Daten werden dauerhaft gelöscht und können nicht wiederhergestellt werden.",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#d7dee6',
					cancelButtonColor: '#7488a3',
					confirmButtonText: 'Ja, löschen',
					cancelButtonText: 'Abbrechen'
					}).then((result) => {
					if (result.value) {
						console.log('deleted');
						// swal(
						// 'Gelöscht!',
						// 'Dein Account wurde gelöscht',
						// 'success'
						// );
						$.get(`${config.BaseURL}api/deleteuser`);
						this.$store.commit('setToken', '');
						this.$router.push('/login');
					} else {
						console.log('cancelled');
						//swal('Abbrechen', 'Ihr Account ist aktiv', 'info')
					}
				})


				 		// $.get('${config.BaseURL}api/deleteuser');
						// this.$store.commit('setToken', '');
						// this.$router.push('/login');
		},
		loadedEvent() {
			this.$store.commit('setCurrentURL', this.$route.path);

			this.$store.commit('track', {
				type: 'page',
				action: 'open'
			});

			if (!this.isLoggedIn) {
				if (!this.isWhitelisted(this.$route)) {
					this.$store.commit('setLoginPage', this.$route.path);
					this.$router.push('/login');
				}
			}
		}
	},
	watch: {
		showContact(to) {
			if (to) {
				this.$nextTick(() => {
					this.contact = true;
				});
			}
		},
		contact(to) {
			if (!to) {
				window.setTimeout(() => {
					this.showContact = false;
					if (this.userData) {
						this.contactForm = {
							type: 'Feedback',
							firstname: this.userData.firstname,
							lastname: this.userData.lastname,
							email: this.userData.email,
							subject: '',
							text: ''
						};
					} else {
						this.contactForm = {
							type: 'Feedback',
							firstname: '',
							lastname: '',
							email: '',
							subject: '',
							text: ''
						};
					}
				}, 500);
			}
		},
		'$route' (to, from) {
			this.$store.commit('setCurrentPage', 'main');
			this.$store.commit('setCurrentURL', this.$route.path);

			this.$store.commit('track', {
				type: 'page',
				action: 'open'
			});

			this.$store.commit('track', {
				type: 'page',
				action: 'leave',
				path: from.path
			});

			if (!this.isLoggedIn && !this.isWhitelisted(this.$route)) {
				this.$store.commit('setLoginPage', this.$route.path);
				this.$router.push('/login');
			}
		},
		userData(to) {
			if (to && to.id) {
				if (this.isWhitelisted(this.$route)) {
					this.$router.push(this.loginPage);
				}

				this.contactForm.firstname = to.firstname;
				this.contactForm.lastname = to.lastname;
				this.contactForm.email = to.email;

				if (this.redirect && this.redirect !== null && typeof this.redirect !== 'undefined') {
  				location.href = `${this.redirect}/#/settoken/${to.token}`;
				}
			}
		},
		loaded() {
			this.loadedEvent();
		}
	},
	created() {
		
		console.log('application started ...')
		if (this.loaded) {
			this.loadedEvent();
		}
	},
	components: {
		GridLoader,
		VueRecaptcha
	},
  name: 'App',
  computed: {
    ...mapGetters([
    	'currentUser',
  		'accessError',
  		'currentPage',
  		'loaded',
  		'isLoggedIn',
  		'userData',
  		'loginPage',
  		'isWhitelisted',
  		'showHomeButton',
  		'redirect'
    ])
  }
}

</script>
<style lang="scss">
  .siteColor {
    background-color: #7488a3;
  }

	.portal {
		.innerContent {
				padding-top: 50px;
				padding-bottom: 50px;
		}
	}

	.allianzInput.primary--text {
		color: #003781 !important;
	}

	.allianzDialog .grid-list-md {
		width: 100%;
	}
</style>

<style lang="scss" scoped>
	.btns {
		display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
		border-radius: 4px;
		color: #333;
    background-color: #fff;
		border-color: #ccc;
		float: left;
	}

	.btns:hover {
		color: #333;
		background-color: #e6e6e6;
		border-color: #adadad;
	}

	.allianzBtn {
		color: #fff;
    background-color: #003781;
    border-color: #003781;
    border-radius: 0px;
	}

	.allianzBtn:hover {
		color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
	}

	.disabledButton {
		color: #333 !important;
		background-color: #e6e6e6 !important;
		border-color: #adadad !important;
	}

	.settings {
		float: right;
		width: 36px;
		height: 36px;
	}

	.loader {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-content: center;
		justify-content: center;
		align-items: center;
	}

	.errorMessage {
		width: 100%;
		text-align: left;
		color: rgb(180, 0, 0);
		font-weight: bold;
	}

	.no-access {
		text-align: center;
	}

	.home {
		width: 35px;
		margin-left: 10px;
	}

	.logout-link {
		cursor: pointer;
		color: #222222;
	}

	.logout-link:hover {
		text-decoration: underline;
	}

	.footerBar a {
		text-decoration: none;
	}

  #statisticButton {
    float:right;
    margin:0 15px 0 0;
  }
</style>
