import 'babel-polyfill';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import vuetify from './plugins/vuetify';

import Vuex from 'vuex';
import VueUpload     from '@websanova/vue-upload/dist/v2/vue-upload.esm.js';
import VueUploadHttp from '@websanova/vue-upload/dist/drivers/http/axios.esm.js';
import config from '@/config.js';
import $ from 'jquery';
import Cookies from 'js-cookie';
import './fonts.css';
import './vuetify.css';
import Sortable from './sortable.js';
// import Sortable from 'vue-sortable'
import uuid from 'uuid-v4';
import axios from 'axios';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.config.productionTip = false

window.$ = $;


Vue.use(Vuex);
Vue.use(Sortable);

Vue.use(VueUpload, {
  plugins: {
      http: Vue.axios
  },
  drivers: {
      http: VueUploadHttp
  }
});


const debug = process.env.NODE_ENV === 'development';

const getCssPath = (el) => {
  if (!(el instanceof Element)) return;
  let path = [];
  try {
    while (el.nodeType === Node.ELEMENT_NODE) {
      let selector = el.nodeName.toLowerCase();
      if (el.id) {
        selector += '#' + el.id;
      } else {
        let sib = el;
        let nth = 1;
        while (sib.nodeType === Node.ELEMENT_NODE && (sib = sib.previousSibling) && nth++); // eslint-disable-line
        selector += ':nth-child(' + nth + ')';
      }
      path.unshift(selector);
      el = el.parentNode;
    }
    return path.join(' > '); // eslint-disable-line
  } catch (error) {
    console.info('check logs');

    }
};

const session = uuid();
Cookies.set('uuid', session, { 
        secure: true,
        sameSite: 'strict'
});


const store = new Vuex.Store({
  state: {
    videos: null,
    currentPage: 'main',
    currentUser: null,
    token: undefined,
    loginPage: '/',
    currentURL: '/',
    categories: null,
    groups: null,
    media: null,
    users: null,
    dateRange: {},
    redirect: null
    // log: null,
  },
  mutations: {
    redirect(state, redirect) {
      if (redirect) {
        state.redirect = redirect;
      }
    },
    trackClick(state, event) {
      if (state.currentUser === null || !state.currentUser.id) {
        return;
      }

      let css = getCssPath(event.target);
      let x = event.clientX;
      let y = event.clientY;

      let w = window;
      let d = document;
      let e = d.documentElement;
      let g = d.getElementsByTagName('body')[0];

      let windowWidth = w.innerWidth || e.clientWidth || g.clientWidth;
      let windowHeight = w.innerHeight || e.clientHeight || g.clientHeight;

      let data = {
        path: state.currentURL,
        x,
        y,
        windowWidth,
        windowHeight,
        css,
        session
      };

   //   $.post((debug ? config.BaseURL : '/') + 'api/log/click', data);
    },
    track(state, data) {
      if (state.currentUser === null || !state.currentUser.id) {
        return;
      }

      if (!data.path) {
        data.path = state.currentURL;
      }

      data.session = session;

      if (debug) {
      //  console.table(data);
      }

      state.lastAction = uuid();

   //   $.post((debug ? config.BaseURL : '/') + 'api/log/action', data);
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setCurrentURL(state, url) {
      state.currentURL = url;
    },
    setLoginPage(state, page) {
      if (page !== '/logout') {
        state.loginPage = page;
      } else {
        state.loginPage = '/';
      }
    },
    setToken(state, token) {
      if (!token) {
        state.currentUser = {};
      }

      // Cookies.set('jwt', token, {
      //   secure: config.secureCookies,
      //   sameSite: 'strict',
      // });

      state.token = token;

      $.ajaxSetup({
        beforeSend: function(xhr) {
          let csrf = $('meta[name="csrf-token"]').attr('content');
          if (csrf) {
            xhr.setRequestHeader('X-CSRF-Token', csrf);
          }
          xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
          if (token) {
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
          }
        }
      });
    },
    getUserData(state) {
      $.get(`${config.BaseURL}api/user/status`).then((user) => {
        state.currentUser = user;
      }, () => {
        state.currentUser = {};
      });
    },
    getCategories(state) {
      state.categories = null;

      $.get(`${config.BaseURL}api/categories`).then((res) => {
        state.categories = res.categories;
      }, () => {
        state.categories = [];
      });
    },
    getGroups(state) {
      state.groups = null;

      $.get(`${config.BaseURL}api/groups`).then((res) => {
        state.groups = res.groups;
      }, () => {
        state.groups = [];
      });
    },
    getMedia(state) {
      state.media = null;

      $.get(`${config.BaseURL}api/media`).then((res) => {
        state.media = res.media.map(e => {
          e.categories = e.category_ids;
          delete e.category_ids;
          return e;
        });
      }, () => {
        state.media = [];
      });
    },
    getAllMedia(state) {
      state.media = null;

      $.get(`${config.BaseURL}api/media/all`).then((res) => {
        state.media = res.media.map(e => {
          e.categories = e.category_ids;
          delete e.category_ids;
          return e;
        });
      }, () => {
        state.media = [];
      });
    },
    getUsers(state) {
      state.users = null;

      $.get(`${config.BaseURL}api/users`).then((res) => {
        state.users = res.users;
      }, () => {
        state.users = [];
      });
    },

    setDateRange(state, dateRange) {
      state.dateRange = dateRange;
    }
  },
  getters: {
    currentUser: state => {
      return state.currentUser && state.currentUser.firstname && state.currentUser.lastname ? `${state.currentUser.firstname} ${state.currentUser.lastname}` : '';
    },
    accessError: (state, getters) => {
      if (getters.isLoggedIn) {
        if (getters.userData.confirmed === 0) {
          return 'Sie müssen zuerst Ihren Account per E-Mail bestätigen.';
        }

        if (getters.userData.allowed === 0) {
          return 'Sie müssen zunächst von einem Allianz Mitarbeiter bestätigt werden. Sobald dies geschehen ist erhalten Sie eine E-Mail.';
        }
      }

      return null;
    },
    currentPage: state => state.currentPage,
    categoriesLoading: state => state.categories === null,
    groupsLoading: state => state.groups === null,
    mediaLoading: state => state.media === null,
    redirect: state => state.redirect,
    media: state => state.media,
    usersLoading: state => state.users === null,
    users: state => state.users,
    allCategories: state => {
      if (!state.categories) {
        return [];
      }

      let getCategories = (arr) => {
        let elements = [];

        arr.forEach(e => {
          elements.push(e);

          if (e.all_children) {
            elements = [...elements, ...getCategories(e.all_children)];
          }
        });

        return elements;
      };

      return getCategories(state.categories);
    },
    groups: state => state.groups,
    categories: state => {
      let empty = {
        value: null,
        sortOrder: state.categories ? state.categories.length : 0,
        text: 'Keine Unterkategorie'
      };

      if (!state.categories) {
        return [ empty ];
      }

      let getCategories = (arr, level = 0) => {
        let elements = [];

        arr.forEach(e => {
          let text = '';

          for (let i = 0; i < level; i++) {
            text += '-';
          }

          text += ' ' + e.name;

          elements.push({
            value: e.id,
            sortOrder: e.all_children ? e.all_children.length : 0,
            text
          });

          if (e.all_children) {
            elements = [...elements, ...getCategories(e.all_children, level + 1)];
          }
        });

        return [empty, ...elements];
      };

      return getCategories(state.categories);
    },
    sparte: state => state.categories,
    loaded: state => !!(state.currentUser !== null),
    token: state => state.token,
    loginPage: state => state.loginPage,
    currentURL: state => state.currentURL,
    isLoggedIn: state => !!(state.currentUser && state.currentUser.id),
    userData: (state, getters) => getters.isLoggedIn ? state.currentUser : null,
    isWhitelisted: () => (route) => {
      let whitelist = [
        'Login',
        'register',
        'confirmed',
        'confirmerror',
        'Request',
        'Reset',
        'Imprint'
      ];

      return whitelist.includes(route.name);
    },
    showHomeButton: (state) => {
      let pages = [
        '/filmportal',
        '/imprint'
      ];

      return pages.includes(state.currentURL);
    },
    dateRange: (state) => state.dateRange
  }
});

store.commit('setToken', Cookies.get('jwt'));
store.commit('getUserData');

$(document).on('click', (e) => {
  store.commit('trackClick', e);
});

$(document).on('click', '[target="_blank"]', function() {
  store.commit('track', {
    type: 'link',
    action: 'extern',
    ref: $(this).attr('href')
  });
});


//cookie popup
window.onload = function () {

  if (Cookies.get('acceptedCookiePolicy') !== 'true') {
    // Show cookie popup
    var cookiePopupContainer = document.createElement("div");
    cookiePopupContainer.setAttribute('id', 'cookiePopupContainer');
    var cookiePopup = document.createElement("div");
    cookiePopup.setAttribute('class', 'cookiePopup');
    var popupText = document.createElement("div");
    popupText.setAttribute('class', 'policytext');
    popupText.innerHTML = 'Wir verwenden technisch notwendige Cookies, die zur Funktion unserer Website erforderlich sind.'
      + 'Sie können Ihren Browser so einstellen, dass diese Cookies blockiert oder Sie über diese Cookies benachrichtigt werden. Einige Bereiche der Website funktionieren dann aber möglicherweise nicht.';
    var btnAccept = document.createElement("button");
    btnAccept.setAttribute('class', 'btn-accept');
    btnAccept.innerHTML = 'Ok';
    cookiePopup.appendChild(popupText);
    cookiePopup.appendChild(btnAccept);
    cookiePopupContainer.appendChild(cookiePopup);
    document.body.appendChild(cookiePopupContainer);
    btnAccept.addEventListener('click', function () {
      Cookies.set('acceptedCookiePolicy', 'true', { 
        secure: true, 
        expires: 365,
        sameSite: 'strict',
      });
      // Use removeChild on parent insted of remove, because its not working in IE
      cookiePopupContainer.parentNode.removeChild(cookiePopupContainer);
    });
  }
  
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  session: session
}).$mount('#app')