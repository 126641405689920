<template>
  <v-date-picker
    :min="min"
    :max="max"
    :ref="pickerName"
    :key="pickerName"
    v-model="pickerDate"
    locale="de-de"
    no-title
    scrollable
    :first-day-of-week="1">
    <template slot="title">Titel
    </template>
  </v-date-picker>
</template>
<script>
  import {EventBus} from '../EventBus/eventBus';

  export default {
    name: 'SingleNpsDatePicker',
    data() {
      return {
        pickerDate: ''
      }
    },
    created() {
      this.pickerDate = this.pickerDateDefault;
    },
    props: ['pickerName','pickerDateDefault','min','max'],
    watch: {
      pickerDate() {
        var self = this;
        EventBus.$emit('singleDatePickerUpdated', [self.pickerName, self.pickerDate]);
      }
    },
    methods: {
    },
  }
</script>
