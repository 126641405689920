<template>
  <div id="statistics-view">
    <v-container grid-list-lg>
    <v-row wrap>
      <v-col sm="12">
        <v-card>
          <v-card-title><h3 class="headline mb-0">Nutzer Statistik</h3></v-card-title>
          <v-card-text><header-filter></header-filter></v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12">
        <users-total></users-total>
      </v-col>
      <v-col sm="12">
        <users-per-day></users-per-day>
      </v-col>
      <v-col sm="12">
        <users-per-hour></users-per-hour>
      </v-col>
      <v-col sm="12">
        <external-links></external-links>
      </v-col>
      <v-col sm="12">
        <video-plays></video-plays>
      </v-col>
      <v-col sm="12">
        <site-ranking></site-ranking>
      </v-col>
    </v-row>
    </v-container>
  </div>
</template>

<script>
  import UsersPerDay from "./users-per-day";
  import UsersPerHour from "./users-per-hour";
  import HeaderFilter from "./filter/header-filter";
  import ExternalLinks from "./external-links";
  import VideoPlays from "./video-plays";
  import SiteRanking from "./site-ranking";
  import UsersTotal from "./users-total";
  export default {
    name: 'Statistic',
    components: {
      UsersTotal,
      SiteRanking,
      VideoPlays,
      ExternalLinks,
      HeaderFilter,
      UsersPerHour,
      UsersPerDay
    }
  }
</script>
<style>
  table.table tbody td a {
    font-size:13px;
  }
</style>
