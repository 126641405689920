<template>
  <v-container fluid grid-list-sm px-3 class="statistic-header-filter">
  <v-row row wrap>
    <date-range-wrapper></date-range-wrapper>
  </v-row>
</v-container>
</template>

<script>
  import DateRangeWrapper from './date-range-wrapper'

  export default {
    name: 'HeaderFilter',
    components: {
      DateRangeWrapper
    },
  }
</script>
<style lang="css">
  .statistic-header-filter {
    background-color: #fff;

  }
</style>
