<template>
    <div class="admin" v-if="!mediaLoading">
      <h1>Upload</h1>
      <v-card >
        <v-container fluid grid-list-lg>
          <v-row>
            <v-col sm="3">
              <v-img class="media" :src="config.BaseURL + video.preview" cover></v-img>
            </v-col>
            <v-col sm="9">
              <v-row>
                <v-col sm="4" class="firstRow">
                  <v-row>
                    <v-col sm="12">
                      <v-text-field label="Headline" :rules="rules" v-model="video.title" required :disabled="saving"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" class="firstRow">
                      <v-menu ref="menu" :close-on-content-click="false" :close-on-click="false" v-model="menu" transition="scale-transition" offset-y :nudge-right="40" min-width="290px" :return-value.sync="video.use_til">
                        <template v-slot:activator="{ on }">
                          <v-text-field v-on="on" label="Ablaufdatum" :value="use_til" append-icon="event" :rules="rules" readonly :disabled="saving">
                          </v-text-field>
                        </template>
                        <v-date-picker v-model="video.use_til" locale="de-de" no-title scrollable :first-day-of-week="1">
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">Abbrechen</v-btn>
                          <v-btn text color="primary" @click="$refs.menu.save(video.use_til)">OK</v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12">
                      <v-text-field label="Vorschau" :value="video.preview" readonly :disabled="saving"></v-text-field>
                      <v-progress-circular v-if="$upload.meta('preview').status === 'sending'" indeterminate color="primary"></v-progress-circular>

                      <v-btn v-else small @click="$upload.select('preview')" class="uploadButton">Vorschau hochladen</v-btn>

                      <div v-if="$upload.files('preview').error.length" class="text-danger">
                          {{ errorMessage }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12">
                      <v-text-field label="Video" :value="video.video" readonly :disabled="saving"></v-text-field>
                      <v-progress-circular v-if="$upload.meta('video').status === 'sending'" indeterminate color="primary"></v-progress-circular>

                      <v-btn v-else small @click="$upload.select('video')" class="uploadButton">Video hochladen (Limit 100MB)</v-btn>

                      <div v-if="$upload.files('video').error.length" class="text-danger">
                          {{ errorMessageVideo }}
                      </div>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col sm="12">
                      <v-select :items="cats" v-model="video.categories" label="Kategorien" single-line bottom multiple chips :disabled="saving || categoriesLoading"></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col sm="8">
                  <v-row>
                    <v-col sm="12">
                      Beschreibung:<br>
                      <!-- <vue-ckeditor v-model="video.description" style="width:100%; height: 400px; border: 1px;" :disabled="saving" ref="editor" language="de-de" /> -->
                      <vue-editor ref="vue-editor" v-model="video.description" :editorOptions="options" :editorToolbar="customToolbarConfirm" style="width:100%; height: 400px; border: 1px;" lang="de-de"></vue-editor>
                      <div v-if="error" class="text-danger lastError">
                          {{ error }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon color="success" @click="submit" :loading="saving">
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn icon color="error" @click="close" :disabled="saving">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <v-progress-circular v-else indeterminate color="primary" class="loading"></v-progress-circular>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '@/config.js';
import $ from 'jquery';

import { VueEditor } from '@/plugins/vue2editor/vue2-editor.esm.js';


export default {
  name: 'VideoUpload',
    components: {
     VueEditor
  },
  data() {
    return {
      config,
      error: null,
      saving: false,
      showVideo: false,
      previewImage: null,
      menu: false,
      rules: [
        v => !!v || 'Dieses Feld wird benötigt',
        v => v.length <= 250 || 'Der Inhalt des Feldes ist zu lang.'
      ],
      video: {
        title: '',
        use_til: '',
        description: '',
        preview: 'static/images/placeholder.png',
        video: '',
        size: 0,
        duration: '',
        categories: []
      }
    }
  },
  watch: {
    mediaLoading() {
      if (this.$route.params.id && !this.mediaLoading) {
        this.media.forEach(media => {
          if (media.id === parseInt(this.$route.params.id, 10)) {
            this.video = {
              ...this.video,
              ...media
            };
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'categories',
      'categoriesLoading',
      'mediaLoading',
      'media'
    ]),
    viewPath() {
        return config.BaseURL + 'media/view/' + this.dl.id;
    },
    cats() {
      return this.categories.filter(e => e.value !== null);
    },
    errorMessage() {
      if (this.$upload.files('preview').error.length > 0) {
        let error = this.$upload.files('preview').error[0].errors[0];
        //console.log(error);
        if (error.rule === 'extension') {
          return 'Die Datei muss vom Typen jpeg, jpg, png, gif sein.';
        }

        if (error.rule === 'size') {
          return 'Die Datei muss kleiner als 4 MB sein.';
        }

        return error.message;
      }

      return null;
    },
    errorMessageVideo() {
      if (this.$upload.files('video').error.length > 0) {
        let error = this.$upload.files('video').error[0].errors[0];
       // console.log(error);
        if (error.rule === 'extension') {
          return 'Die Datei muss vom Typ .mp4 sein.';
        }

        if (error.rule === 'size') {
          return 'Die Datei muss kleiner als 100 MB sein.';
        }

        return error.message;
      }

      return null;
    },
    use_til() {
      let parts = this.video.use_til.split('-');
      if (parts.length === 3) {
        return `${parts[2]}.${parts[1]}.${parts[0]}`;
      }

      return '';
    }
  },
  methods: {
    close() {
      this.$router.push({name: 'admin.media'});
    },
    submit() {
      this.saving = true;
      $.post(config.BaseURL + 'api/media/add', this.video).then(() => {
        this.error = null;

        this.close();
      }, (res) => {
        this.saving = false;

        if (res.responseJSON && res.responseJSON.errors) {
          this.error = Object.keys(res.responseJSON.errors).map(key => res.responseJSON.errors[key][0])[0];
        } else {
          this.error = 'Es ist ein Fehler aufgetreten';
        }
      });
    },
    toggleVideo() {
      this.showVideo = !this.showVideo;
    }
  },
  created() {
    
    if (!this.userData || this.userData.admin !== 1) {
      this.$router.push('/');
    }

    this.$store.commit('getCategories');
    if (this.$route.params.id) {
      this.$store.commit('getAllMedia');
    }

    this.$store.commit('setCurrentPage', 'portal');

    const self = this;    

    this.$upload.on('preview', {
        url: "/api/upload/image",
        maxSizePerFile: 4194304,
        maxFiles: 1,
        onSuccess(_, res) {
         // console.log(res.url)
          self.video.preview = res.url;
        },
        onError(err) {
         // console.log(err)
        },
        onSelect() {
          //
        },
        http(data) {
          $.ajax({
              url: data.url,
              dataType: 'json',
              cache: false,
              contentType: false,
              processData: false,
              data: data.body,
              type: 'post',
              success: data.success,
              error: (res) => {
                if (res.responseJSON && res.responseJSON.errors) {
                  let error = Object.keys(res.responseJSON.errors).map(key => res.responseJSON.errors[key][0]);

                  data.error({
                    data: {
                      msg: error[0]
                    }
                  });
                } else {
                  data.error({
                    data: {
                      msg: 'Unbekannter Fehler'
                    }
                  });
                }
              },
              progress: data.progress
          });
        }
    });

    this.$upload.on('video', {
        url: "/api/upload/video",
        maxSizePerFile: 100 * 1024 * 1024,
        maxFiles: 1,
        extensions: [
          'mp4'
        ],
        onSuccess(_, res) {
          self.video.video = res.url;
          self.video.size = res.size;
          self.video.duration = res.duration;
        },
        onError(err) {
        },
        onSelect() {
          //
        },
        http(data) {
          $.ajax({
              url: data.url,
              dataType: 'json',
              cache: false,
              contentType: false,
              processData: false,
              data: data.body,
              type: 'post',
              success: data.success,
              error: (res) => {
                if (res.responseJSON && res.responseJSON.error) {
                  let error = Object.keys(res.responseJSON.errors).map(key => res.responseJSON.errors[key][0]);

                  data.error({
                    data: {
                      msg: error[0]
                    }
                  });
                } else {
                  data.error({
                    data: {
                      msg: 'Unbekannter Fehler'
                    }
                  });
                }
              },
              progress: data.progress
          });
        }
    });
  },
  mounted() {

    // this.$upload.option("preview", "url", config.server + "/api/upload/image");

    // this.$upload.option("video", "url", config.server + "/api/upload/video");

    this.$upload.reset('preview', {
        url: config.BaseURL + 'api/upload/image'
    });

    this.$upload.reset('video', {
        url: config.BaseURL + 'api/upload/video'
    });
  }
}
</script>

<style lang="scss">
  .admin .card__media__content {
    justify-content: center;
  }

  .admin .grid-list-lg {
    width: auto;
    padding-bottom: 0px;
  }
</style>

<style lang="scss" scoped>
  .admin {
    width: 100%;
    margin-top: -30px;
  }

  h1 {
    font-size: 1.8em !important;
    color: #003781;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .uploadButton {
    text-transform: none;
  }

  .loading {
    margin-top: 20%;
    left: calc(50% - 16px);
  }

  .media {
    text-align: center;
    padding: 33%;
  }

  .play {
    width: 80px;
    height: 80px;
    display: col;
    align-self: center;
    justify-self: center;
  }

  .closeVideo {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 1000;
  }

  .firstRow {
    padding-top: 0px !important;
    margin-top: -8px !important;
  }

  .lastError {
    margin-top: 40px;
  }
</style>
