<template>
    <div class="admin">
      <div class="row">
        <div v-for="(link, key) in links" @click="changeTo(link.link)" :key="key" class="col-md-3">
          <v-card v-show="showLink(link)" class="card" @mouseover="link.raised = true" @mouseout="link.raised = false" :raised="link.raised">
            <v-card-title primary-title>
              <div class="text-center">
                <v-icon>{{ link.icon }}</v-icon> {{ link.title }}
              </div>
            </v-card-title>
          </v-card>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Adminportal',
  data() {
    return {
      links: [
        {
          title: 'Kategorien',
          icon: 'folder',
          link: '/admin/categories',
          raised: false,
          superAdminOnly: false
        },
        {
          title: 'Videos',
          icon: 'camera_roll',
          link: '/admin/media',
          raised: false,
          superAdminOnly: false
        },
        {
          title: 'Benutzer',
          icon: 'account_box',
          link: '/admin/users',
          raised: false,
          superAdminOnly: false
        },
        {
          title: 'Gruppen',
          icon: 'group',
          link: '/admin/groups',
          raised: false,
          superAdminOnly: false
        },
        {
          title: 'Statistik',
          icon: 'assessment',
          link: '/statistic',
          raised: false,
          superAdminOnly: true,
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  methods: {
    changeTo(url) {
      this.$router.push(url);
    },
    showLink(link){
      if(link.superAdminOnly === true && !this.userData.superadmin){
        return false;
      }
      return true;
    }
  },
  created() {
    if (!this.userData || this.userData.admin !== 1) {
      this.$router.push('/');
    }
  }
}
</script>

<style lang="scss" scoped>
  .admin {
    width: 100%;
  }

  .card {
    cursor: pointer;
  }

  .text-center {
    width: 100%;
    font-weight: bold;
  }

  .col-md-3 {
    margin-bottom: 25px;
  }
</style>
