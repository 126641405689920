<template>
    <div>
      <p>Herzlichen Glückwunsch. Sie haben Ihre E-Mailadresse erfolgreich für das Allianz Maklertools Portal bestätigt.</p>

      <p>Nach erfolgreicher Prüfung werden Sie unmittelbar freigeschaltet und per E-Mail benachrichtigt.</p>

      <p>Gerne stellen wir Ihnen auch unsere Beratungssuite zur Erstellung von Beratungsseiten sowie unsere Filme zum Download zur Verfügung. Um Ihnen jeweils den Zugang zu ermöglichen, benötigen wir:<br /><br />
        - für die Beratungssuite die unterschriebene <a href="static/downloads/nutzungsbedingungen_beratungssuite.pdf" target="_blank" rel="noopener noreferrer">Nutzungsvereinbarung</a>.<br />
        - für die Filme den ausgefüllten und unterschriebenen <a href="static/downloads/PDF_Filmnutzungsrechte.pdf" target="_blank" rel="noopener noreferrer">Lizenzvertrag</a>.<br /><br />
        Schicken Sie uns den jeweiligen per Mail an <a href="mailto:mv-zugang@allianz.de">mv-zugang@allianz.de</a>.
      </p>

      <p>Bei Fragen steht Ihnen wie gewohnt Ihr/e Allianz Maklerbetreuer/in zur Verfügung.</p>

      <p>Viele Grüße<br />
      Ihr Allianz Maklervertrieb</p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Confirmed'
}
</script>

<style scoped>
</style>
