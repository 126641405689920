<template>
  <div class="container">
    <div v-for="cat in category" :key="cat.id" class="category-element">
      <div v-bind:id="cat.categoryName" class="category-link">
        <img v-if="cat.type === 'beratungssuite'" @click="submitForm" :src="cat.categoryImgUrl" class="category-image" />
        <a v-else-if="cat.link && (!cat.permission || userData[cat.permission])" :href="parseLink(cat.link)" target="_blank" rel="noopener noreferrer">
            <img :src="cat.categoryImgUrl" class="category-image">
        </a>
        <img v-else-if="cat.link && cat.permission && !userData[cat.permission]" :src="cat.categoryImgUrl" class="category-image" @click="categoryModalLink(cat)">


        <img v-else-if="cat.target" :src="cat.categoryImgUrl" class="category-image" @click="categoryModal(cat.target)">
        <img v-else :src="cat.categoryImgUrl" class="category-image noPointer">

        <div v-if="cat.flag" class="flag">
            {{ cat.flag }}
        </div>
      </div>

      <div v-if="showModal == true">
        <div class="modal-overlay">
            <h2>Lizenzvertrag liegt nicht vor</h2>
            <p class="modal-paragraph">
                {{ anrede }},<br /><br />
                Sie besitzen noch nicht die notwendige Berechtigung zum Download der Filmdateien.<br /><br />
                Um Ihnen den Zugang zu ermöglichen, benötigen wir den ausgefüllten und<br />unterschriebenen Lizenzvertrag von Ihnen.<br /><br />
                Der Download ist <a href="static/downloads/PDF_Filmnutzungsrechte.pdf" target="_blank" rel="noopener noreferrer">hier</a> möglich.<br /><br />
                Reichen Sie ein unterschriebenes Exemplar bitte über Ihren/e Maklerbetreuer/in oder<br />per E-Mail ein an: <a href="mailto:mv-zugang@allianz.de">mv-zugang@allianz.de</a>.<br /><br />
                Nach erfolgreicher Prüfung werden Sie unmittelbar freigeschaltet und per E-Mail benachrichtigt.
            </p>
            <input @click="closeModal" value="Verstanden" type="button" />
        </div>
        <div class="modalBg" @click="showModal = false">
        </div>
      </div>

      <div v-if="showModalBeratung == true">
        <div class="modal-overlay">
            <h2>Nutzungsvereinbarung liegt nicht vor</h2>
            <p class="modal-paragraph">
                {{ anrede }},<br /><br />
                Sie besitzen noch nicht die notwendige Berechtigung zur Nutzung der Beratungssuite.<br><br>
                Um Ihnen den Zugang zu ermöglichen, benötigen wir die unterschriebene <a href="static/downloads/nutzungsbedingungen_beratungssuite.pdf" target="_blank" rel="noopener noreferrer">Nutzungsvereinbarung</a>.<br /><br />
                Reichen Sie ein unterschriebenes Exemplar bitte über Ihren/e Maklerbetreuer/in oder<br />per E-Mail ein an: <a href="mailto:mv-zugang@allianz.de">mv-zugang@allianz.de</a>.<br /><br />
                Nach erfolgreicher Prüfung werden Sie unmittelbar freigeschaltet und per E-Mail benachrichtigt.
            </p>
            <input @click="closeModalBeratung" value="Verstanden" type="button" />
        </div>
        <div class="modalBg" @click="showModalBeratung = false">
        </div>
      </div>
    </div>

    <form ref="login" :action="action" method="post" target="_blank" rel="noopener noreferrer">
        <input type="hidden" name="token" :value="this.userData ? this.userData.token : ''" />
    </form>
  </div>
</template>

<script>

/* eslint-disable */
import { mapGetters } from 'vuex';
import config from '@/config.js';

export default {
  data() {
    return {
        category: [
            {
                id: 0,
                categoryName: "beratungssuite",
                categoryImgUrl: "static/images/kacheln/kachel_0.jpg",
                link: `${config.beratungssuite}/#/settoken/:token`,
                modal: 'showModalBeratung',
                permission: 'signed_makler',
                type: 'beratungssuite'
            },
            {
                id: 5,
                categoryName: "zielgruppenflyer",
                categoryImgUrl: "/static/images/kacheln/kachel_5.jpg",
                link: 'https://folienpool-beratungscharts.de/zielgruppenflyer',
                type: 'default'

            },
            {
                id: 1,
                categoryName: "go-to-meeting",
                categoryImgUrl: "static/images/kacheln/kachel_1.jpg",
                link: 'https://www.gotomeeting.com/de-de/lp/allianz',
                type: 'default'
            },
            {
                id: 2,
                categoryName: "seo",
                categoryImgUrl: "static/images/kacheln/kachel_2.jpg",
                link: 'https://netgrade.de/makler',
                type: 'default'
                //flag: 'Ab Q4 2018 verfügbar'
            },
            {
                id: 3,
                categoryName: "filmportal",
                categoryImgUrl: "static/images/kacheln/kachel_3.jpg",
                target: '/filmportal',
                type: 'default'
            },
            {
                id: 4,
                categoryName: "bookingtime",
                categoryImgUrl: "static/images/kacheln/kachel_4.jpg",
                link: 'https://allianz-makler.onlinetermine.com',
                type: 'default'
            }
        ],
        showModal: false,
        showModalBeratung: false,
        checked: false,
    }
  },
  name: 'Categories',
  computed: {
      ...mapGetters([
          'userData'
      ]),
      action() {
          return `${config.beratungssuite}/setAPI`;
      },
      parseLink() {
          return (link) => {
            if (!this.userData) {
                return link.replace(':token', '');
            }

            return link.replace(':token', this.userData.token);
          };
      },
      anrede() {
          if (this.userData.salutation === 'Herr') {
              return `Sehr geehrter Herr ${this.userData.lastname}`;
          }

          return `Sehr geehrte Frau ${this.userData.lastname}`;
      }
  },
  methods: {
    submitForm() {
        debugger;
        this.$refs.login.submit();
    },
    closeModal(){
        this.showModal = false;

        this.$store.commit('track', {
            type: 'modal',
            action: 'close',
            ref: 'no_license'
        });
    },
    closeModalBeratung() {
        this.showModalBeratung = false;

        this.$store.commit('track', {
            type: 'modal',
            action: 'close',
            ref: 'no_license_beratung'
        });
    },
    categoryModal(url) {
        if (this.userData.signed) {
            this.$router.push(url);
        } else {
            this.showModal = true;

            this.$store.commit('track', {
				type: 'modal',
                action: 'open',
                ref: 'no_license'
			});
        }
    },
    categoryModalLink(cat) {
        this[cat.modal] = true;

        this.$store.commit('track', {
            type: 'modal',
            action: 'open',
            ref: 'no_license_' + cat.modal
        });
    }
  }
}
</script>

<style lang="scss" scoped>
    h2 {
        font-size: 18px;
    }
    .category-link {
        width: 100%;
    }

    .category-element {
        position: relative;
        width: calc(50% - 15px);
        height: auto;
        float: left;
        margin-right: 15px;
    }
    .category-element:nth-child(odd) {
        width: calc(50% - 15px);
        margin-right: 0;
        margin-left: 15px;
    }
    // .category-element:first-child {
    //     position: relative;
    //     width: 100%;
    //     height: auto;
    //     float: left;
    //     padding: 0;
    //     margin: 0;
    // }
    .category-image {
        width: 95%;
        margin-bottom:30px;
    }
    .category-image:hover {
        cursor: pointer;
    }

    .modal-overlay {
        z-index: 9999;
        background-color: white;
        padding: 15px 30px;
        max-height: calc(100% - 100px);
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-size: 16px;
    }
    .modal-paragraph {
        margin-bottom: 40px;
        margin-top: 10px;
        font-size: 16px;
    }
    .modalBg {
        z-index:9998;
        position:fixed;
        height:100%;
        width:100%;
        top: 0;
        left:0;
    }
    input[type=button] {
        background-color: #003781;
        border: 0;
        color: #ffffff;
        padding: 5px 15px;
        position: absolute;
        right: 50px;
        bottom: -16px;
    }

    .noPointer {
        cursor: default !important;
    }

    .flag {
        background-color: #ff9138;
        color: white;
        padding: 10px 20px;
        font-size: 18px;
        font-weight: bold;
        position: absolute;
        top: 30px;
        right: 0px;
    }
</style>
