<template>
  <v-row wrap>
    <v-col sm="6">
      <v-text-field
        label="Startdatum"
        prepend-icon="event"
        v-model="dateRangeFormated.start"
        readonly
        @click="dialog = true"
        hide-details
      ></v-text-field>
    </v-col>

    <v-col sm="6">
      <v-text-field
        label="Enddatum"
        prepend-icon="event"
        v-model="dateRangeFormated.end"
        readonly
        hide-details
        @click="dialog = true"
      ></v-text-field>
    </v-col>

    <v-col sm="12">
      <v-dialog
        v-model="dialog"
        hide-overlay
        transition="fade-transition"
        scrollable
        max-width="599px"
      >
        <v-card>
          <v-container fluid grid-list-md>
            <v-row wrap>
              <v-col sm="6" class="text-center">
                <single-nps-date-picker pickerName="start" :pickerDateDefault="dateRange.start"
                                        :min="min" :max="max"
                                        labelText="Startdatum"></single-nps-date-picker>
              </v-col>
              <v-col sm="6" class="text-center">
                <single-nps-date-picker pickerName="end" :pickerDateDefault="dateRange.end" :min="min"
                                        :max="max" labelText="Enddatum"></single-nps-date-picker>
              </v-col>
              <v-col sm="12" class="text-center">
                <v-btn @click="saveDateRange" color="info">Speichern</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
  import SingleNpsDatePicker from "../../../components/NpsDatePicker/singleNpsDatePicker";
  import {EventBus} from '../../../components/EventBus/eventBus';
  import DateRangeHelper from '../../../mixins/DateRangeHelper/component';

  export default {
    name: 'DateRangeWrapper',
    mixins: [DateRangeHelper],
    components: {
      SingleNpsDatePicker
    },
    data() {
      return {
        dialog: false,
        min: '2018-02-21',
        max: new Date().toJSON().substring(0, 10),
        dateRange: {
          start: '',
          end: ''
        },
        dateRangeFormated: {
          start: '',
          end: ''
        },
        validator: {
          formError: false,
          formErrorMessage: '',
          dateRange: {
            start: '',
            end: '',
          },
        }
      }
    },
    created() {
      this.setDefaults();
      this.setEventHandlers();

      this.$nextTick(function () {
        this.saveDateRange();
      });
    },
    methods: {
      setDefaults() {
        this.setDateRange(this.min, this.max);
        this.setValidatorDateRange(this.min, this.max);
      },
      setEventHandlers() {
        var self = this;
        EventBus.$on('singleDatePickerUpdated', function ([name, value]) {
          self.validator.dateRange[name] = value;
        })
      },
      setDateRange(start, end) {
        this.dateRange.start = start;
        this.dateRange.end = end;
        this.dateRangeFormated.start = this.formatDate(this.dateRange.start);
        this.dateRangeFormated.end = this.formatDate(this.dateRange.end);
      },
      setValidatorDateRange(start, end) {
        this.validator.dateRange.start = start;
        this.validator.dateRange.end = end;
      },
      saveDateRange() {
        var self = this;
        if (self.validateDateRange() === true) {
          self.setDateRange(self.validator.dateRange.start, self.validator.dateRange.end);
          self.$store.commit('setDateRange', self.dateRange);

          EventBus.$emit('filterUpdated');
          self.dialog = false;
        }
      },
      validateDateRange() {
        let validator = this.validator;

        if (new Date(validator.dateRange.start) > new Date(validator.dateRange.end)) {
          validator.formError = true;
          validator.formErrorMessage = "Startdatum darf nicht nach dem Enddatum liegen.";

          return false;
        }

        validator.formError = false;
        validator.formErrorMessage = "";

        return true;
      },
    },
  }
</script>
