<script>
  /**
   * this is the ajax get method for all components which require ajax calls and an items property
   */
  import {EventBus} from "../../components/EventBus/eventBus";
  import config from '../../config.js';

  export default {
    name: 'FetchData',
    data() {
      return {
        debug: false,
        items: [],
        /**
         * url must be defined in component, will be overwritten in this mixin via merging
         * https://vuejs.org/v2/guide/mixins.html#Option-Merging
         */
        ajaxUrl: '',
      }
    },
    created() {
      this.checkDevelopment();

      var self = this;
      EventBus.$on("filterUpdated", function () {
        self.fetchData();
      })
    },
    methods: {
      getParams() {
        return {
          dateRange: this.$store.getters.dateRange,
        }
      },
      checkDevelopment() {
        if (process.env.NODE_ENV == 'development') {
          this.debug = true;
        }
      },
      fetchData() {
        let self = this;
        let urlFormated = config.BaseURL + self.ajaxUrl;
        EventBus.$emit("fetchDataStarted", self.ajaxUrl);

        $.ajax(urlFormated, {
          method: 'GET',
          dataType: 'json',
          params: self.getParams(),

        }).then((res) => {

          if (res && res.hasOwnProperty('items')) {
            self.items = res.items;
            EventBus.$emit("fetchDataFinished", self.ajaxUrl);
          }
        }).catch(function (error) {
          console.log(error);
        });
      }
    }
  }
</script>
