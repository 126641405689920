<template>
    <div class="create">
      <h1 v-if="user.id">Nutzer bearbeiten</h1>
      <h1 v-else>Nutzer anlegen</h1>
      <v-container fluid>
          <v-row>
            <v-col offset-xs-2 xs-8>
              <v-card class="paddingRow">
                <v-container fluid>
                  <v-row>
                    <v-col xs-5>
                      <v-container fluid>
                        <v-row>
                          <v-col xs-12>
                            <v-select
                              :items="salutation"
                              v-model="user.salutation"
                              label="Anrede"
                              bottom
                              :rules="rules"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs-12>
                            <v-text-field label="Vorname" :rules="rules" v-model="user.firstname" required :disabled="saving || usersLoading"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs-12>
                            <v-text-field label="Nachname" :rules="rules" v-model="user.lastname" required :disabled="saving || usersLoading"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs-12>
                            <v-text-field label="VTNR" :rules="rules" mask="##/###/####" return-masked-value v-model="user.vtnr" required :disabled="saving || usersLoading"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                        <v-flex xs12>
                            <v-select
                              :items="sparte"
                              v-model="user.sparte"
                              label="Sparte"
                              bottom
                              :rules="rules"
                              required
                              :disabled="saving || usersLoading"
                            ></v-select>
                          </v-flex>
                        </v-row>



                        <v-row>
                          <v-col xs-12>
                            <v-text-field label="E-Mail" :rules="rules" v-model="user.email" required :disabled="saving || usersLoading"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs-12>
                            <v-text-field v-if="user.id" label="Passwort" v-model="user.password" :disabled="saving || usersLoading" type="password"></v-text-field>
                            <v-text-field v-else label="Passwort" :rules="rules" v-model="user.password" required :disabled="saving || usersLoading" type="password"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row v-if="error">
                          <v-col xs-12>
                            <div class="text-danger">{{ error }}</div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>

                    <v-col offset-xs-2 xs-5>
                      <v-container fluid>
                        <v-row>
                          <v-col xs-12>
                            <v-text-field label="Maklerbüro" :rules="rules" v-model="user.company" required :disabled="saving || usersLoading"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs-12>
                            <v-text-field label="Vertriebseinheit" v-model="user.poolname" :disabled="saving || usersLoading"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col xs-12>
                            <v-text-field label="Straße" :rules="rules" v-model="user.street" required :disabled="saving || usersLoading"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs-12>
                            <v-text-field label="Hausnummer" :rules="rules" v-model="user.number" required :disabled="saving || usersLoading"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs-12>
                            <v-text-field label="PLZ" :rules="rules" mask="#####" v-model="user.zip" required :disabled="saving || usersLoading"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col xs-12>
                            <v-text-field label="Ort" :rules="rules" v-model="user.city" required :disabled="saving || usersLoading"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon color="success" @click="submit" :loading="saving" :disabled="usersLoading">
                    <v-icon>save</v-icon>
                  </v-btn>
                  <v-btn icon color="error" @click="deleteItem" :disabled="saving || usersLoading">
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '@/config.js';

export default {
  name: 'VideoUpload',
  data() {
    return {
      config,
      error: null,
      saving: false,
      rules: [
        v => !!v || 'Dieses Feld wird benötigt',
        v => v.length <= 250 || 'Der Inhalt des Feldes ist zu lang.'
      ],
      salutation: [
        {
          value: '',
          text: 'Bitte wählen...'
        },
        'Herr',
        'Frau'
      ],
      sparte: [
        'Leben',
        'Kranken'
      ],
      user: {
        salutation: '',
        firstname: '',
        lastname: '',
        company: '',
        vtnr: '',
        sparte: '',
        poolname: '',
        email: '',
        street: '',
        number: '',
        zip: '',
        city: '',
        password: ''
      }
    }
  },
  watch: {
    usersLoading() {
      if (this.$route.params.id && !this.usersLoading) {
        this.users.forEach(user => {
          if (user.id === parseInt(this.$route.params.id, 10)) {
            this.user = {
              ...this.user,
              ...user
            };
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'users',
      'usersLoading'
    ])
  },
  methods: {
    deleteItem() {
      this.$router.push({
        name: 'admin.users'
      });
    },
    submit() {
      this.saving = true;
      $.post(config.BaseURL + 'api/users/save', this.user).then(() => {
        this.error = null;

        this.$router.push({
          name: 'admin.users'
        });
      }, (res) => {
        this.saving = false;

        if (res.responseJSON && res.responseJSON.errors) {
          this.error = Object.keys(res.responseJSON.errors).map(key => res.responseJSON.errors[key][0])[0];
        } else {
          this.error = 'Es ist ein Fehler aufgetreten';
        }
      });
    }
  },
  created() {
    if (!this.userData || this.userData.admin !== 1) {
      this.$router.push('/');
    }

    if (this.$route.params.id || this.usersLoading) {
      this.$store.commit('getUsers');
    }
  }
}
</script>

<style lang="scss">
  .create .card__media__content {
    justify-content: center;
  }

  .create .grid-list-lg {
    padding-bottom: 0px;
  }
</style>

<style lang="scss" scoped>
  .paddingRow {
    padding: 25px;
  }

  .create {
    width: 100%;
    margin-top: -30px;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 1.5em !important;
    color: #003781;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .uploadButton {
    text-transform: none;
  }

  .media {
    text-align: center;
    padding: 19%;
    cursor: pointer;
  }

  .play {
    width: 80px;
    height: 80px;
    display: col;
    align-self: center;
    justify-self: center;
  }

  .closeVideo {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 1000;
  }

  .firstRow {
    padding-top: 0px !important;
    margin-top: -8px !important;
  }

  .lastError {
    margin-top: 40px;
  }
</style>
