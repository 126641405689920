<template>
    <v-list>
        <div class="sortable" v-sortable="options" id="list">
            <div v-for="(item, key) in items" :key="key" :rel="item.id">
                <v-list-item-content class="">
                    <v-list-item :key="key" class="pa-2 lightgrey">
                        <v-list-item-title :style="'padding-left: ' + (30 * level) + 'px;'">{{ item.name }}</v-list-item-title>
                    </v-list-item>

                    <v-list-item-action class="floatRight">
                        <v-dialog v-model="item.dialog" persistent max-width="290">
                            <template v-slot:activator="{ on }">
                                <v-btn class="" fab dark small color="red" v-on="on">
                                    <v-icon color="white">delete</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="headline">Möchtest du die Kategorie {{ item.name }} wirklich löschen?</v-card-title>
                                <v-card-text>Es werden auch alle verknüpften Medien gelöscht. Es ist nicht möglich diesen Vorgang rückgängig zu machen.</v-card-text>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" text @click.native="item.dialog = false;">Nein</v-btn>
                                <v-btn color="green darken-1" text @click.native="item.dialog = false; deleteItem(item.id);">Ja</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                    </v-list-item-action>

                    <v-list-item-action class="floatRight2">
                        <v-btn class="" fab dark small color="orange" @click="edit(item.id)">
                            <v-icon color="white">mode_edit</v-icon>
                        </v-btn>
                    </v-list-item-action>

                    <v-divider></v-divider>
                </v-list-item-content>


                <treelist v-if="item.all_children.length > 0" :items="item.all_children" :level="level + 1" class="subtree" />
            </div>
        </div>
    </v-list>
</template>

<script>
/* eslint-disable */
import treelist from '@/components/TreeList.vue';
import config from '@/config.js';

export default {
  name: 'treelist',
  data() {
      return {
          dialog: false,
          options: {
              group: `sort-level-${this.level}`,
              onStart: this.startSort,
              onEnd: this.stopSort
          }
      };
  },
  props: {
      items: {
          default: []
      },
      level: {
          default: 0
      }
  },
  components: {
      treelist
  },
  methods: {
    startSort() {
        $(this.$el).find('.subtree').hide();
    },
    stopSort() {
        $(this.$el).find('.subtree').show();

        let items = $(this.$el).find('.sortable').eq(0).children().toArray().map(e => {
            return parseInt($(e).attr('rel'), 10);
        });

        $.post(config.BaseURL + 'api/categories/sort', {items});
    },
    edit(id) {
        this.$router.push({
            name: 'admin.editCategory',
            params: {
                id
            }
        });
    },
    deleteItem(id) {
        $.get(config.BaseURL + 'api/categories/delete/' + id).then(() => {

          this.$store.commit('getCategories');
        }, () => {});
    }
  }
}
</script>

<style>
    .sortable-drag .subtree {
        display: none;
    }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .floatRight {
        position: absolute;
        right: 5px;
        height: 55px;
    }

    .floatRight2 {
        position: absolute;
        right: 55px;
        height: 55px;
    }
    .lightgrey {
        border-bottom: 1px solid lightgrey;
    }
</style>
