<template>
  <div class="login">
    <div class="row" v-if="!success">
        <div class="col-md-6">
            <div class="row mx-5">
                <div class="col-md-3 col-md-offset-3 form-label">
                    Altes Passwort
                </div>
                <div class="col-md-6">
                    <input v-model="oldpassword" type="password" />
                </div>
            </div>
            <div class="row mx-5">
                <div class="col-md-3 col-md-offset-3 form-label">
                    Passwort
                </div>
                <div class="col-md-6">
                    <input v-model="password" type="password" />
                </div>
            </div>
            <div class="row mx-5">
                <div class="col-md-3 col-md-offset-3 form-label">
                    Passwort wiederholen
                </div>
                <div class="col-md-6">
                    <input v-model="password_confirmation" type="password" />
                </div>
            </div>
            <div class="row mx-5">
                <div class="col-md-9">
                   <span style="font-size:12px;"> Das Passwort sollte mindestens einen Großbuchstaben, einen Kleinbuchstaben, einen numerischen Wert und ein Sonderzeichen enthalten und muss länger als 8 Zeichen lang sein.</span>
                </div>
            </div>
            <div class="row mx-5">
                <div class="col-md-9 col-md-offset-3">
                    <vue-recaptcha style="width: 100%;" :loadRecaptchaScript="true" ref="recaptcha" @verify="onVerify" @expired="onExpired" sitekey="6Le1XkYUAAAAAONwMr7tgshbd15lV14YQPvUtPNY"></vue-recaptcha>
                     <!-- <vue-recaptcha style="width: 100%;" ref="recaptcha" @verify="onVerify" @expired="onExpired" sitekey="6LcDTJsUAAAAANvb_3QtxMDKxT0X_mOyY71pOC3g"></vue-recaptcha> -->
                </div>
            </div>
            <div class="row mx-5" v-if="errorMessage">
                <div class="col-md-9 col-md-offset-3 form-label errorMessage">
                    {{ errorMessage }}
                </div>
            </div>
            <div class="row mx-5">
                <div class="col-md-12 text-right">
                    <input v-if="loading" value="Passwort ändern" class="disabled" type="button" disabled />
                    <input v-else @click="login" value="Passwort ändern" type="button" />
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-else>
        <div class="col-md-6">Ihr Passwort wurde geändert. Klicken Sie <router-link to="/">hier</router-link> um zur Startseite zurückzukehren.</div>
    </div>
  </div>
</template>

<script>

import config from '@/config.js';
import VueRecaptcha from 'vue-recaptcha';

/* eslint-disable */
export default {
  name: 'PasswordReset',
  data() {
      return {
          captcha: false,
          oldpassword: '',
          password: '',
          password_confirmation: '',
          e1: false,
          e2: false,
          loading: false,
          errorMessage: null,
          success: false
      };
  },
  components: { VueRecaptcha },
  methods: {
    login() {
        this.loading = true;
        let data = {
            oldpassword: this.oldpassword,
            password: this.password,
            password_confirmation: this.password_confirmation,
            captcha: this.captcha
        };

        $.post(`${config.BaseURL}api/password/change`, data).then((res) => {
            this.loading = false;
            this.errorMessage = null;
            this.success = true;
        }, (error) => {
            if (error.responseJSON && error.responseJSON.errors) {
                let messages = Object.keys(error.responseJSON.errors).map(key => error.responseJSON.errors[key][0]);

                this.errorMessage = messages[0];
            } else {
                this.errorMessage = 'Es ist ein Problem aufgetreten. Versuchen Sie es später noch einmal.';
            }

            this.loading = false;
        });
    },
    onVerify(res) {
        this.captcha = res;
    },
    onExpired() {
        this.captcha = false;
    }
  }
}
</script>

<style scoped>
    .form-label {
        padding: 5px 0;
    }
    input[type=text], input[type=password] {
        width: 100%;
        margin-bottom: 10px;
        border: 0px;
        background-color: rgba(255,255,255,0.7);
        padding: 5px 10px;
    }
    input[type=button] {
        background-color: #003781;
        border: 0;
        color: #ffffff;
        padding: 5px 15px;
    }

    .passwort-vergessen {
        margin-left: 10px;
        line-height: 20px;
        font-size: 14px;
    }

    .passwort-vergessen a {
        font-size: 17px;
    }

    .passwort-vergessen div {
        font-size: 16px;
    }

    .lock-img {
        height: 40px;
        width: auto;
    }
    .lock-img, .passwort-vergessen {
        float: left;
    }

    .errorMessage {
        color: rgb(180, 0, 0);
        font-weight: bold;
        text-align: right;
        padding-right: 15px;
    }

    .disabled {
        opacity: 0.7;
    }
</style>
