<template>
<div class="container" v-if="ready">
    <div class="row">
        <div class="category-subheading">Film auswählen</div>  
    </div>
    <div class="row">
        <div @mouseover="mouseOver" @mouseout="mouseOut" class="sparte">
        <span class="menu-title">{{ label }}</span>
        <div class="arrow-down"></div>
            <ul class="sparte-menu" v-show="hover">
                <Sparte v-for="item in sparte" v-model="category" :item="item" :key="item.id"></Sparte>
                <Sparte v-model="category" :item="all"></Sparte>
            </ul>
        </div>
    </div>
    <div class="section">
        <DownloadSingle v-for="download in downloads" :dl="download" :key="download.id"></DownloadSingle>
    </div>
</div>
<div class="loadingSpinner" v-else>
    <v-progress-circular indeterminate color="allianz" class="mx-auto loading"></v-progress-circular>
</div>
</template>

<script>
/* eslint-disable */

import Sparte from '../components/Sparte.vue';
import DownloadSingle from '../components/DownloadSingle.vue';
import { mapGetters } from 'vuex';

export default {
	data(){
		return {
            category: null,
            hover: false,
            all: {
                name: '<b>Alle</b>',
                all_children: [],
                id: null
            }
		}
	},
    methods: {
        mouseOver() {
            this.hover = true;
        },
        mouseOut() {
            this.hover = true; //false
        }
    },
    computed: {
        ...mapGetters([
            'categoriesLoading',
            'sparte',
            'mediaLoading',
            'media'
        ]),
        ready() {
            return !this.categoriesLoading && !this.mediaLoading;
        },
        currentCategory() {
            let find = (categories) => {
                for (let i = 0; i < categories.length; i++) {
                    if (categories[i].id === this.category) {
                        return categories[i];
                    }

                    if (categories[i].all_children) {
                        let recurse = find(categories[i].all_children);

                        if (recurse) {
                            return recurse;
                        }
                    }
                }

                return null;
            };

            return find(this.sparte);
        },
        label() {
            if (this.currentCategory) {
                return this.currentCategory.name;
            }

            return 'Sparte';
        },
        downloads() {
            if (!this.category) {
                return this.media;
            }
            return this.media.filter(download => {
                if (!download.categories) {
                    return false;
                }

                if (download.categories.includes(this.category)) {
                    return true;
                }

                if (this.currentCategory && this.currentCategory.all_children) {
                    for (let i = 0; i < this.currentCategory.all_children.length; i++) {
                        if (download.categories.includes(this.currentCategory.all_children[i].id)) {
                            return true;
                        }
                    }
                }

                return false;
            });
        }
    },
    created() {
        this.$store.commit('setCurrentPage', 'portal');
        this.$store.commit('getCategories');
        this.$store.commit('getMedia');
    },
    components: {
        Sparte,
        DownloadSingle
    }
}
</script>

<style scoped>
.menu-title {
    color: #003781;
    font-weight: bold;
}
.category-subheading {
    text-transform:uppercase;
    font-weight: bold;
    color: #003781;
    font-size: 18px;
}
.sparte {
    position: absolute;
    margin-top: 5px;
    background-color: #ececec;
    color: #000000;
    padding: 5px 15px;
    padding-right: 25px;
    font-weight: bold;
    z-index: 9999;
    cursor: pointer;
}
.sparte:hover{
    cursor:pointer;
}
.arrow-down {
    position: absolute;
    top: 13px;
    right: 7px;
    width: 0;
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #003781;
}

.loadingSpinner {
    margin-top: 20%;
    display: flex;
}

ul {
    list-style:none;
    padding: 0;
    position: absolute;
    background-color: #ececec; 
    padding: 5px 15px;
    top: 28pxr;
    left: 0;
    width:250px;
}
li {
    padding: 15px 0;
}

.cathidden {
    display:none;
}
.hassubcat {
    width: 20px;
    height: 80px;
    position: absolute;
    background-color: red;
}
.subcat {
    position:absolute;
    top: 0;
    left: 251px;
}

.section {
    margin-top:56px;
}

</style>
