import index from '@/views/admin/index';
import media from '@/views/admin/media';
import users from '@/views/admin/users';
import upload from '@/views/admin/upload';
import categories from '@/views/admin/categories';
import createCategory from '@/views/admin/createCategory';
import groups from '@/views/admin/groups';
import createGroup from '@/views/admin/createGroup';
import createUser from '@/views/admin/createUser';

export default [
    {
        path: '/admin',
        redirect: {
            name: 'admin.index'
        }
    },
    {
        path: '/admin/index',
        name: 'admin.index',
        component: index
    },
    {
        path: '/admin/media',
        name: 'admin.media',
        component: media
    },
    {
        path: '/admin/users',
        name: 'admin.users',
        component: users
    },
    {
        path: '/admin/media/upload',
        name: 'admin.media.upload',
        component: upload
    },
    {
        path: '/admin/media/edit/:id',
        name: 'admin.editMedia',
        component: upload
    },
    {
        path: '/admin/users/edit/:id',
        name: 'admin.editUser',
        component: createUser
    },
    {
        path: '/admin/users/create',
        name: 'admin.createUser',
        component: createUser
    },
    {
        path: '/admin/categories',
        name: 'admin.categories',
        component: categories
    },
    {
        path: '/admin/categories/create',
        name: 'admin.createCategory',
        component: createCategory
    },
    {
        path: '/admin/categories/edit/:id',
        name: 'admin.editCategory',
        component: createCategory
    },
    {
        path: '/admin/groups',
        name: 'admin.groups',
        component: groups
    },
    {
        path: '/admin/groups/create',
        name: 'admin.createGroup',
        component: createGroup
    },
    {
        path: '/admin/groups/edit/:id',
        name: 'admin.editGroup',
        component: createGroup
    }
];
