
import Sortable from 'sortablejs';

let vSortable = {};

if (!Sortable) {
  throw new Error('[vue-sortable] cannot locate Sortable.js.');
}

// exposed global options
vSortable.config = {};

vSortable.install = (Vue) => {
  Vue.directive('sortable', (el, args) => {


try {

  let options = args.value || vSortable.config;

  let sortable = new Sortable(el, options);

  if (this.arg && !this.vm.sortable) {
    this.vm.sortable = {};
  }

  //  Throw an error if the given ID is not unique
  if (this.arg && this.vm.sortable[this.arg]) {
    console.warn('[vue-sortable] cannot set already defined sortable id: \'' + this.arg + '\'');
  } else if ( this.arg ) {
    this.vm.sortable[this.arg] = sortable;
  }
} catch (error) {
  console.info('check logs')
}   



  });
};

export default vSortable;
