<template>
  <v-card height=100%>
    <v-card-title>
      <div>
        <h3 class="headline mb-0">Besucherzahlen pro Tag</h3>
      </div>
    </v-card-title>
    <v-card-text>
      <line-chart :chartData="items"></line-chart>
    </v-card-text>
  </v-card>
</template>
<script>
  import LineChart from '../../components/Charts/line-chart'
  import FetchData from '../../mixins/FetchData/component';

  export default {
    name: 'UsersPerDay',
    components: {LineChart},
    mixins: [FetchData],
    data() {
      return {
        ajaxUrl: 'api/users-per-day',
      }
    }
  }
</script>
