<template>
    <div class="create">
      <h1 v-if="category.id">Kategorie bearbeiten</h1>
      <h1 v-else>Kategorie anlegen</h1>
      <v-container fluid>
          <v-row>
            <v-col offset-xs-4 xs-4>
              <v-card class="paddingRow">
                <v-container fluid>
                  <v-row row>
                    <v-col xs-12>
                      <v-text-field label="Name" :rules="rules" v-model="category.name" required :disabled="saving || categoriesLoading"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row row>
                    <v-col xs-12>
                      <v-select ref="parent" :items="categories" v-model="category.parent_id" label="Überkategorie" single-line bottom :disabled="saving || categoriesLoading"></v-select>
                    </v-col>
                  </v-row>

                  <v-row row v-if="error">
                    <v-col xs-12>
                      <div class="text-danger">{{ error }}</div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon color="success" @click="submit" :loading="saving" :disabled="categoriesLoading">
                    <v-icon>save</v-icon>
                  </v-btn>
                  <v-btn icon color="error" @click="deleteItem" :disabled="saving || categoriesLoading">
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '@/config.js';

export default {
  name: 'VideoUpload',
  data() {
    return {
      config,
      error: null,
      saving: false,
      rules: [
        v => !!v || 'Dieses Feld wird benötigt',
        v => v.length <= 250 || 'Der Inhalt des Feldes ist zu lang.'
      ],
      category: {
        name: '',
        parent_id: null,
        sort_order: null
      }
    }
  },
  watch: {
    categoriesLoading() {
      if (this.$route.params.id && !this.categoriesLoading) {
        this.allCategories.forEach(cat => {
          if (cat.id === parseInt(this.$route.params.id, 10)) {
            this.category.id = cat.id,
            this.category.name = cat.name,
            this.category.parent_id = cat.parent_id;
            this.category.sort_order = cat.sort_order;
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'categories',
      'allCategories',
      'categoriesLoading'
    ])
  },
  methods: {
    deleteItem() {
      this.$router.push({
        name: 'admin.categories'
      });
    },
    submit() {
      // console.log(this.category.sort_order);
      // if (this.category.sort_order === null) {
      //   this.category.sort_order = this.$refs.parent.selectedItem.sortOrder;
      // }
      this.saving = true;
      $.post(config.BaseURL + 'api/categories/save', this.category).then(() => {
        this.error = null;

        this.$router.push({
          name: 'admin.categories'
        });
      }, (res) => {
        this.saving = false;

        if (res.responseJSON && res.responseJSON.errors) {
          this.error = Object.keys(res.responseJSON.errors).map(key => res.responseJSON.errors[key][0])[0];
        } else {
          this.error = 'Es ist ein Fehler aufgetreten';
        }
      });
    }
  },
  created() {
    if (!this.userData || this.userData.admin !== 1) {
      this.$router.push('/');
    }

    this.$store.commit('getCategories');
  }
}
</script>

<style lang="scss">
  .create .card__media__content {
    justify-content: center;
  }

  .create .grid-list-lg {
    padding-bottom: 0px;
  }
</style>

<style lang="scss" scoped>
  .paddingRow {
    padding: 25px;
  }

  .create {
    width: 100%;
    margin-top: -30px;
  }

  h1 {
    font-size: 1.5em !important;
    color: #003781;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .uploadButton {
    text-transform: none;
  }

  .media {
    text-align: center;
    padding: 19%;
    cursor: pointer;
  }

  .play {
    width: 80px;
    height: 80px;
    display: flex;
    align-self: center;
    justify-self: center;
  }

  .closeVideo {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 1000;
  }

  .firstRow {
    padding-top: 0px !important;
    margin-top: -8px !important;
  }

  .lastError {
    margin-top: 40px;
  }
</style>
