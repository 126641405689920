<template>
    <div class="admin">
      <div class="actions">
        <v-btn @click="$router.push({name: 'admin.index'})" color="error" fab small class="mx-2">
          <v-icon>keyboard_backspace</v-icon>
        </v-btn>

        <v-btn @click="$router.push('media/upload')" color="success" fab small class="mx-2">
          <v-icon>add</v-icon>
        </v-btn>
      </div>
      <v-row>
        <v-col xs="12" sm="6" class="offset-sm-1">
            <v-card>
                <v-toolbar color="allianz" dark>
                    <v-toolbar-title>Videos</v-toolbar-title>
                </v-toolbar>

                <v-list v-if="!mediaLoading">
                  <div v-for="(item, index) in media" :key="index">
                    <v-list-item ripple>
                      <v-list-item-avatar>
                        <v-img :src="config.BaseURL + item.preview"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content class="pa-2">
                        <v-list-item-title v-html="item.title"></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action class="floatRight2">
                        <v-btn class="mx-2 pa-1" fab dark small color="orange" @click="edit(item.id)">
                            <v-icon color="white">mode_edit</v-icon>
                        </v-btn>
                      </v-list-item-action>

                      <v-list-item-action class="floatRight">
                        <v-dialog v-model="item.dialog" persistent max-width="290">
                            <template v-slot:activator="{ on }">
                              <v-btn class="mx-2 pa-1" fab dark small color="red" v-on="on">
                                  <v-icon color="white">delete</v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="headline">Möchtest du das Video {{ item.title }} wirklich löschen?</v-card-title>
                                <v-img :src="config.BaseURL + item.preview" height="200px"></v-img>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" text @click.native="item.dialog = false;">Nein</v-btn>
                                <v-btn color="green darken-1" text @click.native="item.dialog = false; deleteItem(item.id);">Ja</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="index + 1 < media.length" :key="index"></v-divider>
                  </div>
                </v-list>
                <v-progress-circular v-else indeterminate color="allianz" class="mx-auto loading"></v-progress-circular>
            </v-card>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import config from '@/config.js';

export default {
  name: 'Categories',
  data() {
    return {
      config
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'media',
      'mediaLoading'
    ])
  },
  methods: {
    deleteItem(id) {
        $.get(config.BaseURL + 'api/media/delete/' + id).then(() => {

          this.$store.commit('getAllMedia');
        }, () => {});
    },
    edit(id) {
        this.$router.push({
            name: 'admin.editMedia',
            params: {
                id
            }
        });
    }
  },
  created() {
    if (!this.userData || this.userData.admin !== 1) {
      this.$router.push('/');
    }

    this.$store.commit('setCurrentPage', 'portal');
    this.$store.commit('getAllMedia');
  }
}
</script>

<style lang="scss">
  *:focus {
    outline: 0 !important;
  }

  .actions .icon {
    font-size: 22px !important;
  }
</style>

<style lang="scss" scoped>
  .admin {
    width: 100%;
  }

  .actions {
    margin-top: 20px;
    margin-bottom: 20px;
    z-index:1000;
  }

  .actions > * {
    font-size: 16px !important;
  }

  .loading {
    display: block !important;
    height: 70px !important;
  }

      .floatRight {
        position: absolute;
        right: 0px;
        height: 55px;
    }

    .floatRight2 {
        position: absolute;
        right: 95px;
        height: 55px;
        width: 15px;
    }

</style>
