<template>
  
</template>

<script>
import config from '@/config.js';

export default {
  created() {
    $.get(`${config.BaseURL}api/logout`);
    this.$store.commit('setToken', '');
    this.$router.push('/login');
  }
}
</script>
