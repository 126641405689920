<script>
  import VueCharts from 'vue-chartjs';
  import { Line, mixins } from 'vue-chartjs';

  export default {
    name: 'LineChart',
    extends: Line,
    mixins: [mixins.reactiveProp],
    props : ['chartData'],
    data(){
      return {
        options: {
          legend: {
            display: false,
          },
          responsive: true,
          maintainAspectRatio: false,
          borderColor: "#0479b2",
        },
      }
    },
    mounted () {
      this.renderChart(
        this.chartData,
        this.options
      );
    }
  }
</script>
