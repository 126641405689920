<template>
    <div class="admin">
      <div class="actions">
        <v-btn @click="$router.push({name: 'admin.index'})" color="error" fab small class="mx-2">
          <v-icon>keyboard_backspace</v-icon>
        </v-btn>

        <v-btn @click="$router.push('categories/create')" color="success" fab small class="mx-2">
          <v-icon>add</v-icon>
        </v-btn>
      </div>
      <v-row>
        <v-col xs="12" sm="6" class="offset-sm-1">
            <v-card>
                <v-toolbar color="allianz" dark>
                    <v-toolbar-title>Kategorien</v-toolbar-title>
                </v-toolbar>
                <treelist v-if="!categoriesLoading" :items="sparte" title="Kategorien" />
                <v-progress-circular v-else indeterminate color="allianz" class="mx-auto loading"></v-progress-circular>
            </v-card>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import treelist from '@/components/TreeList.vue';

export default {
  name: 'Categories',
  computed: {
    ...mapGetters([
      'userData',
      'sparte',
      'categoriesLoading'
    ])
  },
  components: {
    treelist
  },
  created() {
    if (!this.userData || this.userData.admin !== 1) {
      this.$router.push('/');
    }

    this.$store.commit('setCurrentPage', 'portal');
    this.$store.commit('getCategories');
  }
}
</script>

<style lang="scss">
  *:focus {
    outline: 0 !important;
  }

  .actions .icon {
    font-size: 22px !important;
  }
</style>

<style lang="scss" scoped>
  .admin {
    width: 100%;
  }

  .actions {
    margin-top: 20px;
    margin-bottom: 20px;
    z-index:1000;
  }

  .actions > * {
    font-size: 16px !important;
  }

  .loading {
    display: block !important;
    height: 70px !important;
  }
</style>
