import Vue from 'vue';
import Router from 'vue-router';
import Categories from '@/views/categories';
import Imprint from '@/views/imprint';
import FilmPortal from '@/views/filmportal';
import Confirmed from '@/views/confirmed';
import ConfirmError from '@/views/confirm-error';
import Statistic from '@/views/statistic/index';

import admin from './admin.js';

import login from './login.js';
import Register from '@/views/register';

Vue.use(Router);

export default new Router({
  routes: [
    ...login,
    {
      path: '/',
      name: 'categories',
      component: Categories
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/imprint',
      name: 'Imprint',
      component: Imprint
    },
    {
      path: '/filmportal',
      name: 'filmportal',
      component: FilmPortal
    },
    {
      path: '/registration/confirmed',
      name: 'confirmed',
      component: Confirmed
    },
    {
      path: '/registration/confirm-error',
      name: 'confirmerror',
      component: ConfirmError
    },
    {
      path: '/statistic',
      name: 'statistic',
      component: Statistic
    },
    ...admin
  ]
});
