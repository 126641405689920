<template>
  <v-card height=100%>
    <v-card-title>
      <div>
        <h3 class="headline mb-0">Besucher nach Gruppen.</h3>
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        :options.sync="pagination"
        :noResultsText="noResultsText"
        :noDataText="noDataText"
        :rowsPerPageItems="rowsPerPageItems"
        :rowsPerPageText="rowsPerPageText"
        item-key="name"
        class="user-total-table"
        :hide-default-footer="true"
      >

        <template slot="headers" slot-scope="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
              @click="changeSort(header.value)"
            >
              <v-icon small>arrow_upward</v-icon>
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <tr :active="props.selected" @click="props.selected = !props.selected">
            <td>{{ props.item.label }}</td>
            <td>{{ props.item.count }}</td>
          </tr>
        </template>
        <template slot="pageText" slot-scope="props">
          {{ props.pageStart }} - {{ props.pageStop }} von {{ props.itemsLength }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
  import DataTables from "../../mixins/DataTables/component";
  import FetchData from '../../mixins/FetchData/component';

  export default {
    name: 'UsersTotal',
    mixins: [FetchData, DataTables],
    data() {
      return {
        ajaxUrl: 'api/users-total',
        headers: [
          {text: 'Name', value: 'label'},
          {text: 'Anzahl', value: 'count'},
        ],
        pagination: {
         sortBy: ["label"],
        },
      }
    }
  }
</script>
<style lang="scss" scoped>
  .v-icon:before {
      content: '' !important;
  }
</style>
