let config;

try {
  config = require('./config.json');
  // do stuff
} catch (ex) {
  config = require('./config.default.json');
}

export default config;
