<template>
    <div class="admin">
      <div class="actions">
        <v-btn @click="$router.push({name: 'admin.index'})" color="error" fab small class="mx-2">
          <v-icon>keyboard_backspace</v-icon>
        </v-btn>

        <v-btn @click="$router.push('groups/create')" color="success" fab small class="mx-2">
          <v-icon>add</v-icon>
        </v-btn>
      </div>
      <v-row>
        <v-col xs="12" sm="6" class="offset-sm-1">
            <v-card>
                <v-toolbar color="allianz" dark>
                    <v-toolbar-title>Gruppen</v-toolbar-title>
                </v-toolbar>
                <v-list v-if="!groupsLoading">
                  <v-list-item-content v-for="(item, key) in groups" :key="key">
                    <v-list-item class="pa-3 lightgrey">
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item>

                    <v-list-item-action class="floatRight">
                        <v-dialog v-model="item.dialog" persistent max-width="290">
                            <template v-slot:activator="{ on }">
                              <v-btn class="mx-2 pa-1" fab dark small color="red" v-on="on">
                                  <v-icon color="white">delete</v-icon>
                              </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="headline">Möchtest du die Gruppe {{ item.name }} wirklich löschen?</v-card-title>
                                <v-card-text>Es ist nicht möglich diesen Vorgang rückgängig zu machen.</v-card-text>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" text @click.native="item.dialog = false;">Nein</v-btn>
                                <v-btn color="green darken-1" text @click.native="item.dialog = false; deleteItem(item.id);">Ja</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                    </v-list-item-action>

                    <v-list-item-action class="floatRight2">
                        <v-btn class="mx-2 pa-1" fab dark small color="orange" @click="edit(item.id)">
                            <v-icon color="white">mode_edit</v-icon>
                        </v-btn>
                    </v-list-item-action>

                    <v-divider></v-divider>
                  </v-list-item-content>
                </v-list>
                <v-progress-circular v-else indeterminate color="allianz" class="mx-auto loading"></v-progress-circular>
            </v-card>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '@/config.js';

export default {
  name: 'Groups',
  computed: {
    ...mapGetters([
      'userData',
      'groupsLoading',
      'groups'
    ])
  },
  methods: {
    edit(id) {
      this.$router.push({
          name: 'admin.editGroup',
          params: {
              id
          }
      });
    },
    deleteItem(id) {
        $.get(config.BaseURL + 'api/groups/delete/' + id).then(() => {
          this.$store.commit('getGroups');
        }, () => {});
    },
  },
  created() {
    if (!this.userData || this.userData.admin !== 1) {
      this.$router.push('/');
    }

    this.$store.commit('setCurrentPage', 'portal');
    this.$store.commit('getGroups');
  }
}
</script>

<style lang="scss">
  *:focus {
    outline: 0 !important;
  }

  .actions .icon {
    font-size: 22px !important;
  }
</style>

<style lang="scss" scoped>
  .admin {
    width: 100%;
  }

  .actions {
    margin-top: 20px;
    margin-bottom: 20px;
    z-index:1000;
  }

  .actions > * {
    font-size: 16px !important;
  }

  .loading {
    display: block !important;
    height: 70px !important;
  }

  .floatRight {
    position: absolute;
    right:  0px;
    height: 50px;
  }

  .floatRight2 {
    position: absolute;
    right: 95px;
    height: 50px;
    width: 0;
  }
  .lightgrey {
        border-bottom: 1px solid lightgrey;
    }
</style>
