<script>
  import {EventBus} from "../../components/EventBus/eventBus";

  export default {
    name: 'DataTables',
    methods: {
      changeSort(column) {
        if (this.pagination.sortBy === column) {
          this.pagination.descending = !this.pagination.descending;
        } else {
          this.pagination.sortBy = column;
          this.pagination.descending = false;
        }
      },
      filterZero(number) {
        if (number === 0) return '-';
        return number;
      }
    },
    data() {
      return {
        ajaxUrl: '',
        loading: false,
        noResultsText: "Keine passenden Einträge gefunden.",
        noDataText: "Keine Daten vorhanden",
        rowsPerPageItems: [5, 10, 25, {"text": "Alle", "value": -1}],
        rowsPerPageText: "Anzahl Zeilen",
        search: '',
        loading: false,
        pagination: {
          sortBy: 'count',
          descending: true
        },
        selected: [],

      }
    },
    created() {
      var self = this;

      EventBus.$on("fetchDataStarted", function (ajaxUrl) {
        if (ajaxUrl == self.ajaxUrl) {
          self.loading = true;
        }
      });

      EventBus.$on("fetchDataFinished", function (ajaxUrl) {
        if (ajaxUrl == self.ajaxUrl) {
          self.loading = false;
        }
      })
    },
  }
</script>
