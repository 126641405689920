<template>
<div class="row parent">
    <div class="col-md-5">
        <video :class="!playing ? 'vid hiddenVideo' : 'vid'" controls controlsList="nodownload">
            <source :src="viewPath" />
        </video>
        <div :class="!playing ? 'videoPlaceholder' : 'videoPlaceholder hiddenImage'" class="" @click="playVideo">
            <div class="video-preview" :style="'background-image: url(' + config.BaseURL + dl.preview + ')'">
                <img src="static/images/play.png" class="play" />
                <div class="video-preview-overlay">
                    <div style="float:left; width:50%; text-align:left">Filmlänge: {{ dl.duration }}</div><div style="width:50%;float:left; text-align:right">Größe: {{ Math.round(dl.size / 1024 / 1024) }} MB</div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-7 column-right">
        <div class="row toprow">
            <div class="col-md-12">
                <div class="video-title">{{ dl.title }}</div>
                <div v-html="dl.description" style="white-space: pre-wrap;"></div>
            </div>
        </div>
        <div class="row bottomrow">
            <div class="col-md-7">
                Verwendung bis:<br>
                {{ calcDate(dl.use_til) }}
            </div>
            <div class="col-md-5 text-right">
                <a :href="downloadPath" target="_blank" rel="noopener noreferrer" @click="download"><input type="button" value="Film Herunterladen" /></a>
            </div>
        </div>
    </div>

</div>

</template>

<script>
/* eslint-disable */
import config from '@/config.js';
import { mapGetters } from 'vuex';

export default {
  name: 'DownloadSingle',
  data() {
      return {
          config,
          playing: false,
          height: 0,
          BaseURL: config.BaseURL
      };
  },
  props: ['dl'],
  computed: {
      ...mapGetters(['userData']),
      calcDate: () => (date) => {
          let parts = date.split('-');

          return `${parts[2]}.${parts[1]}.${parts[0]}`;
      },
      downloadPath() {
          return this.BaseURL + 'media/download/' + this.dl.id;
      },
      viewPath() {
          return this.BaseURL + 'media/view/' + this.dl.id;
      }
  },
  methods: {
    playVideo() {
        this.playing = true;
        $(this.$el).find('video').get(0).play();
        this.$store.commit('track', {
            type: 'video',
            action: 'play',
            ref: this.dl.video
        });
    },
    download() {
        this.$store.commit('track', {
            type: 'video',
            action: 'download',
            ref: this.dl.video
        });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.videoPlaceholder {
    width: 100%;
    height: 100%;
}
.parent {
    margin-top: 16px;
    background-color: #FFFFFF;
    padding: 15px 0;
    display: flex;
}
.video-preview {
    width: 100%;
    cursor: pointer;
    padding-top: (1080 / 1920) * 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.video-title {
    font-weight: bold;
    color: #003781;
    margin-bottom: 5px;
}
.video-preview-overlay {
    background-color: rgba(0,0,0,0.6);
    position:absolute;
    bottom:0px;
    left: 0px;
    color: #FFFFFF;
    width: 100%;
    padding:10px;

}
.toprow {
    height: 85%;
}
.bottomrow {
    margin-top: 10px;
}
input[type=button] {
    background-color: #003781;
    border: 0;
    color: #ffffff;
    padding: 5px 15px;
    position: absolute;
    right: 15px;
    top: 5px;
}

.vid {
    width: 100%;
}

.hiddenVideo {
    display: none;
}

.hiddenImage {
    display: none;
}

.placeholder {
    position: absolute;
    margin-top: -100%;
    opacity: 0;
    margin-bottom: -20px;
}

.play {
    position: absolute;
    top: calc(50% - 166px / 2 - 20px);
    left: calc(50% - 166px / 2);
}

.column-right {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
}
</style>
