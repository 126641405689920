import Login from '@/views/login';
import Logout from '@/views/logout';
import Request from '@/views/email';
import Reset from '@/views/reset';
import Changepassword from '@/views/changepassword';

export default [
    {
        path: '/login/:http?/:redirect?',
        name: 'Login',
        component: Login
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '/request',
        name: 'Request',
        component: Request
    },
    {
        path: '/passwort/reset/:token',
        name: 'Reset',
        component: Reset
    },
    {
        path: '/changepassword',
        name: 'Changepassword',
        component: Changepassword
    }
];
