<template>
  <v-card height=100%>
    <v-card-title>
      <div>
        <h3 class="headline mb-0">Anzahl der geklickten externen Links</h3>
      </div>
      <v-spacer></v-spacer>
      <v-text-field
        append-icon="search"
        label="Suche"
        single-line
        hide-details
        v-model="search"
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        :options.sync="pagination"
        :noResultsText="noResultsText"
        :noDataText="noDataText"
        :rowsPerPageItems="rowsPerPageItems"
        :rowsPerPageText="rowsPerPageText"
        item-key="name"
        class="external-links-table"
      >

        <template slot="headers" slot-scope="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
              @click="changeSort(header.value)"
            >
              <v-icon small>arrow_upward</v-icon>
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <tr :active="props.selected" @click="props.selected = !props.selected">
            <td>{{ filterZero(props.item.count) }}</td>
            <td><a target="_blank" rel="noopener noreferrer" :href="props.item.ref">{{ props.item.ref }}</a></td>
          </tr>
        </template>
        <template slot="pageText" slot-scope="props">
          {{ props.pageStart }} - {{ props.pageStop }} von {{ props.itemsLength }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import DataTables from "../../mixins/DataTables/component";
  import FetchData from '../../mixins/FetchData/component';

  export default {
    name: 'ExternalLinks',
    mixins: [DataTables, FetchData],
    data() {
      return {
        ajaxUrl: 'api/external-links',
        headers: [
          {text: 'Aufrufe', value: 'count'},
          {text: 'URL', value: 'ref'},
        ],
        pagination: {
         sortBy: ["count"],
        },
      }
    },
    methods: {
      filterZero(number) {
        if (number === 0) return '-';
        return number;
      }
    }
  }
</script>
